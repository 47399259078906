import { FC } from "react";
import { Controller } from "react-hook-form";
import { Input } from "reactstrap";
import { FormControlInputProps } from "../../../interfaces";
import { URL_REGEX } from "../../../utils/constant";


const FormControlInput: FC<FormControlInputProps> = ({
  control, type, disabled, required, error, name, placeholder, urlPatternMessage, onChange }) => {
  return (
    <>
      <Controller
        name={name}
        control={control}
        rules={{
          required,
          ...(urlPatternMessage && {
            pattern: {
              value: URL_REGEX,
              message: urlPatternMessage
            }
          })
        }}
        render={(field) => (
          <Input {...field} type={type || 'text'} rows={4} disabled={disabled} placeholder={placeholder}
            onChange={(e) => {
              onChange && onChange(e.target.value)
              field.onChange(e)
            }} />
        )}
      />
      {error && <small className="text-danger">{error}</small>}
    </>
  );
}

export default FormControlInput;
