import { useMutation } from "@apollo/client";
import { FC, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Col, Row } from "reactstrap";
import { BasicInformationFormProps } from "../../../interfaces";
import { IResumeUpdateBasicInfoData, IResumeUpdateBasicInfoFormData } from "../../../Pages/agreements/interfaces";
import { SET_BASIC_INFO } from "../../../Pages/candidate/update-by-resume/gql";
import { ContactPhoneType, countriesOption, COUNTRY_STATES_PROVINCE } from "../../../utils/constant";
import CustomButton from "../../layout/CustomButton";
import FormControlInput from "../common/FormControlInput";
import FormControlSelect from "../common/FormControlSelect";
import SelectAllCheckRow from "../common/SelectAllCheckRow";
import SingleRowWithCheckField from "./SingleRowWithCheckField";
import { useHistory } from "react-router-dom";


const BasicInformationForm: FC<BasicInformationFormProps> = ({
  candidateId, resumeId, handleBack, handleNext, newData, oldData, disabled }) => {
  const history = useHistory()

  const [setBasicInfo, { loading }] = useMutation(SET_BASIC_INFO, {
    onCompleted: () => {
      toast.success("basic info updated successfully")
      handleNext()
    },
    onError: (error) => {
      if (error.message === 'Email Already Exist!') {
        toast.error('Email Already Exist!')
        return
      }

      toast.error("error while updating basic info")
    }
  })

  const [selectAllChecks, setSelectAllChecks] = useState<boolean>(false);

  const defaultAssignment = ({ countryCode, stateCode, phoneType, ...rest }: IResumeUpdateBasicInfoData): IResumeUpdateBasicInfoFormData => ({
    ...rest,
    stateCode: COUNTRY_STATES_PROVINCE(countryCode)?.find(({ value }) => value === stateCode),
    countryCode: countriesOption?.find(({ value }) => value === countryCode),
    email: rest?.email,
    phoneType: ContactPhoneType?.find(({ value }) => value === phoneType?.toLocaleUpperCase())
  })

  const { control, handleSubmit, setValue, getValues, watch, formState: { errors } } = useForm<IResumeUpdateBasicInfoFormData>({
    defaultValues: defaultAssignment(disabled ? oldData : newData),
  });

  const { countryCode } = watch()
  const stateOptions = useMemo(() => {
    return COUNTRY_STATES_PROVINCE(countryCode?.label)
  }, [countryCode])

  const onSubmit = async (data: IResumeUpdateBasicInfoFormData) => {

    const { countryCode, stateCode, email, phoneType, ...rest } = data
    const basicInfo = {
      candidateId, resumeId,
      ...(email !== oldData?.email && { email }),
      phoneType: phoneType?.value, countryCode: countryCode?.value,
      stateCode: countryCode?.value !== 'UK' ? stateCode?.value : '',
      ...rest
    }
    await setBasicInfo({ variables: { basicInfo } })
  };

  return (
    <Col className="p-0 m-0">
      <SelectAllCheckRow newData={!disabled} disabled={disabled} selectAllChecks={selectAllChecks} setSelectAllChecks={setSelectAllChecks} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <div className="my-3 fs-13 fw-700">Basic Info</div>
          <SingleRowWithCheckField selectAll={selectAllChecks} checkBox={!disabled} oldExists label="First Name" required
            onChecked={() => { setValue("firstName", oldData?.firstName) }}
            onUnChecked={() => {
              if (!disabled) {
                setValue("firstName", newData?.firstName)
              }
            }}>
            <FormControlInput required='First Name is Required' name="firstName" placeholder="First Name"
              control={control} disabled={disabled!} error={errors?.firstName?.message} />
          </SingleRowWithCheckField>

          <SingleRowWithCheckField selectAll={selectAllChecks} checkBox={!disabled} oldExists label="Middle Name"
            onChecked={() => { setValue("middleName", oldData?.middleName) }}
            onUnChecked={() => {
              if (!disabled) {
                setValue("middleName", newData?.middleName)
              }
            }}>
            <FormControlInput required={false} name="middleName" placeholder="Middle Name"
              control={control} disabled={disabled!} error={errors?.middleName?.message} />
          </SingleRowWithCheckField>

          <SingleRowWithCheckField selectAll={selectAllChecks} checkBox={!disabled} oldExists required
            label="Last Name" onChecked={() => { setValue("lastName", oldData?.lastName) }}
            onUnChecked={() => {
              if (!disabled) {
                setValue("lastName", newData?.lastName)
              }
            }}>
            <FormControlInput required='Last Name is Required' name="lastName" placeholder="Last Name"
              control={control} disabled={disabled!} error={errors?.lastName?.message} />
          </SingleRowWithCheckField>

        </div>

        <div>
          <div className="my-3 fs-13 fw-700">Primary Contact</div>
          <SingleRowWithCheckField selectAll={selectAllChecks} checkBox={!disabled} oldExists label="Email" required
            onChecked={() => { setValue("email", oldData?.email) }}
            onUnChecked={() => {
              if (!disabled) {
                setValue("email", newData?.email)
              }
            }}>
            <FormControlInput required='Email is Required' name="email" placeholder="Email"
              control={control} disabled={disabled!} error={errors?.email?.message} />
          </SingleRowWithCheckField>

          <SingleRowWithCheckField selectAll={selectAllChecks} checkBox={!disabled} oldExists label="Phone" required
            onChecked={() => {
              setValue("phone", oldData?.phone)
              setValue("phoneType", ContactPhoneType?.find(({ value }) => value === oldData?.phoneType?.toLocaleUpperCase()))
            }}
            onUnChecked={() => {
              if (!disabled) {
                setValue("phone", newData?.phone)
                setValue("phoneType", ContactPhoneType?.find(({ value }) => value === newData?.phoneType?.toLocaleUpperCase()))
              }
            }}
          >
            <Row>
              <Col xs='8'>
                <FormControlInput required='Phone is Required' name="phone" placeholder="Phone"
                  control={control} disabled={disabled!} error={errors?.phone?.message} />
              </Col>

              <Col xs='4'>
                <FormControlSelect control={control} name="phoneType" error={errors?.phoneType?.message} options={ContactPhoneType}
                  placeholder="Phone Type" requiredMessage="Phone Type is Required" disabled={disabled!} />
              </Col>
            </Row>
          </SingleRowWithCheckField>
        </div>

        <div>
          <div className="my-3 fs-13 fw-700">Address</div>
          <SingleRowWithCheckField selectAll={selectAllChecks} checkBox={!disabled} oldExists label="Street"
            onChecked={() => { setValue("streetAddress", oldData?.streetAddress) }}
            onUnChecked={() => {
              if (!disabled) {
                setValue("streetAddress", newData?.streetAddress)
              }
            }}>
            <FormControlInput required={false} name="streetAddress" placeholder="Street Address"
              control={control} disabled={disabled!} error={errors?.streetAddress?.message} />
          </SingleRowWithCheckField>

          <SingleRowWithCheckField selectAll={selectAllChecks} checkBox={!disabled} oldExists label="City" required
            onChecked={() => { setValue("city", oldData?.city) }}
            onUnChecked={() => {
              if (!disabled) {
                setValue("city", newData?.city)

              }
            }}>
            <FormControlInput required='City is Required' name="city" placeholder="City"
              control={control} disabled={disabled!} error={errors?.city?.message} />
          </SingleRowWithCheckField>

          <SingleRowWithCheckField selectAll={selectAllChecks} checkBox={!disabled} oldExists label="State" required={countryCode?.value !== 'UK'}
            onChecked={() => { setValue("stateCode", COUNTRY_STATES_PROVINCE(oldData?.countryCode)?.find(({ value }) => value === oldData?.stateCode)) }}
            onUnChecked={() => {
              if (!disabled) {
                setValue("stateCode", COUNTRY_STATES_PROVINCE(newData?.countryCode)?.find(({ value }) => value === newData?.stateCode))
              }
            }}>
            <FormControlSelect options={stateOptions || COUNTRY_STATES_PROVINCE(getValues()?.countryCode?.value)} requiredMessage={countryCode?.value !== 'UK' ? 'State is Required' : undefined} name="stateCode"
              control={control} disabled={countryCode?.value === 'UK' || !['US', 'UK', 'MX', 'CA', null, undefined, ''].includes(getValues()?.countryCode?.value) || disabled!}
              error={errors?.stateCode?.message} placeholder="State" />
          </SingleRowWithCheckField>

          <SingleRowWithCheckField selectAll={selectAllChecks} checkBox={!disabled} oldExists label="Zip"
            onChecked={() => { setValue("postalCode", oldData?.postalCode) }}
            onUnChecked={() => {
              if (!disabled) {
                setValue("postalCode", newData?.postalCode)
              }
            }}>
            <FormControlInput required={false} name="postalCode" placeholder="Zip"
              control={control} disabled={disabled!} error={errors?.postalCode?.message} />
          </SingleRowWithCheckField>

          <SingleRowWithCheckField selectAll={selectAllChecks} checkBox={!disabled} oldExists label="Country" required
            onChecked={() => { setValue("countryCode", countriesOption?.find(({ value }) => value === oldData?.countryCode)) }}
            onUnChecked={() => {
              if (!disabled) {
                setValue("countryCode", countriesOption?.find(({ value }) => value === newData?.countryCode))
              }
            }}>
            <FormControlSelect requiredMessage='Country is Required' name="countryCode"
              options={countriesOption} placeholder="Country"
              control={control} disabled={disabled!} error={errors?.countryCode?.message} />
          </SingleRowWithCheckField>
        </div>

        <div>
          <div className="my-3 fs-13 fw-700">Social Media</div>
          <SingleRowWithCheckField selectAll={selectAllChecks} checkBox={!disabled} oldExists label="Twitter"
            onChecked={() => { setValue("twitterUrl", oldData?.twitterUrl) }}
            onUnChecked={() => {
              if (!disabled) {
                setValue("twitterUrl", newData?.twitterUrl)
              }
            }}>
            <FormControlInput required={false} name="twitterUrl" placeholder="Twitter URL"
              control={control} disabled={disabled!} error={errors?.twitterUrl?.message}
              urlPatternMessage="Twitter URL is not valid!"
            />
          </SingleRowWithCheckField>

          <SingleRowWithCheckField selectAll={selectAllChecks} checkBox={!disabled} oldExists label="Facebook"
            onChecked={() => { setValue("fbUrl", oldData?.fbUrl) }}
            onUnChecked={() => {
              if (!disabled) {
                setValue("fbUrl", newData?.fbUrl)
              }
            }}>
            <FormControlInput required={false} name="fbUrl" placeholder="Facebook URL"
              urlPatternMessage="Facebook URL is not valid!"
              control={control} disabled={disabled!} error={errors?.fbUrl?.message} />
          </SingleRowWithCheckField>

          <SingleRowWithCheckField selectAll={selectAllChecks} checkBox={!disabled} oldExists label="LinkedIn" required
            onChecked={() => { setValue("linkedInUrl", oldData?.linkedInUrl) }}
            onUnChecked={() => {
              if (!disabled) {
                setValue("linkedInUrl", newData?.linkedInUrl)
              }
            }}>
            <FormControlInput required='LinkedIn Url is Required' name="linkedInUrl" placeholder="LinkedIn URL"
              control={control} disabled={disabled!} error={errors?.linkedInUrl?.message}
              urlPatternMessage="LinkedIn URL is not valid!" />
          </SingleRowWithCheckField>

          <SingleRowWithCheckField selectAll={selectAllChecks} checkBox={!disabled} oldExists label="Instagram"
            onChecked={() => { setValue("instaUrl", oldData?.instaUrl) }}
            onUnChecked={() => {
              if (!disabled) { setValue("instaUrl", newData?.instaUrl) }
            }}>
            <FormControlInput required={false} name="instaUrl" placeholder="Instagram URL"
              control={control} disabled={disabled!} error={errors?.instaUrl?.message} />
          </SingleRowWithCheckField>

        </div>

        {!disabled && (
          <Col className="mt-5">
            <Row
              className="jobOrderRevamp bg-transparent text-right">
              <Col xl='7' className="my-1">
                <CustomButton
                  buttonText="Cancel"
                  buttonColor="secondary"
                  buttonType="button"
                  className="big-width"
                  buttonClick={() => history.goBack()}
                />
              </Col>
              <Col xl='3' className="my-1">
                <CustomButton
                  buttonText="Next"
                  buttonColor="primary"
                  buttonType="submit"
                  className="big-width"
                  loading={loading}
                  disabled={loading}
                />
              </Col>
            </Row>
          </Col>
        )}
      </form>
    </Col >
  );
};

export default BasicInformationForm;
