import { FC, useCallback, useContext, useEffect, useLayoutEffect, useState } from 'react';
// packages block
import {
  Col,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row
} from 'reactstrap';
// components block
import SEARCH_IMG from '../../assets/images/search.svg';
import GoBack from '../../components/GoBack';
import CandidateSearchFilters from './candidate-components/CandidateFilters';
import CandidateSearchList from './candidate-components/CandidateSearchList';
import { ALL_CANDIDATES_ADVANCE_SEARCH } from './gql';
// interfaces, graphql, constants block
import { useLazyQuery } from '@apollo/client';
import { State } from 'country-state-city';
import Creatable from 'react-select/creatable';
import Loader from '../../components/layout/Loader';
import { CANDIDATE_SEARCH, RADIUS_OPTIONS } from '../../constants';
import { AppContext } from '../../context';
import { SelectorOptionType } from '../../interfaces';
import { countriesIsoCodeDto, states_candidate_constant } from '../../utils/constant';
import classNames from 'classnames';
import { CandidateSearchProps } from '../agreements/interfaces';
import { filterDuplicates, filterDuplicatesTitles } from '../../utils/helper';
import BetaBadgeVersion from "../../assets/images/BadgeBetaYellow.svg"

const CandidateSearch: FC<CandidateSearchProps> = ({ jobOrder, hiddenSideBar, tabChange,
  excludeCandidateIds, recruiterId, type, jobOrderId, defaultFilters }) => {
  // states
  const [inputValue, setInputValue] = useState<string>('');
  const [excludeSkills, setExcludeSkills] = useState<string[]>([])
  const [advanceSearch, setAdvanceSearch] = useState<{ label: string, value: string, color: string }[]>([]);
  const [city, setCity] = useState<string>('');
  const [selectedState, setState] = useState<SelectorOptionType>({ label: '', value: '' });
  const [radius, setRadius] = useState<SelectorOptionType>({ label: '', value: '' });
  const [includeJobTitle, setIncludeJobTitle] = useState<SelectorOptionType>({ label: '', value: '' });
  const [stateList, setStateList] = useState<{ label: string; value: string }[]>();
  const [selectedCountry, setSelectedCountry] = useState<string>('');
  const [zipCode, setZipCode] = useState<string>('');
  const [limit, setLimit] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { setContextSkillsESAggs, skillsESAggs } = useContext(AppContext);
  const [searchCall, setSearchCall] = useState<boolean>(false);
  const { theme } = useContext(AppContext)
  // api calls
  const [_getCandidatesList, { loading, data, refetch }] = useLazyQuery(
    ALL_CANDIDATES_ADVANCE_SEARCH
  );

  //customized style for search bar
  const customSelectStyleNew = (theme) => ({
    control: (styles, provided) => ({
      ...styles,
      background: theme === "light" ? provided.background : "#282828",
      borderColor: theme === "light" ? "#ffffff" : "#ffffff",
      color: theme === "light" ? provided.background : "#d9d9d9",
      fontSize: 12,
      "&:hover": {
        borderColor: theme === "light" ? "#ffffff" : "#ffffff",
        color: theme === "light" ? provided.background : "#d9d9d9",
      },
      boxShadow: provided.isFocused && `${theme === "light" ? "#ffffff" : "#ffffff"}`
    }),
    menu: (provided) => ({
      ...provided,
      display: 'none'
    }),
    option: (provided) => ({
      ...provided,
      fontSize: 12,
      background: theme === "light" ? provided.background : "#363636",
      "&:hover": {
        background: theme === "light" ? provided.background : "#474444",
        color: theme === "light" ? provided.background : "#d9d9d9",
      },
    }),
    container: (provided, state) => ({
      ...provided,
      fontSize: 12,
      width: '70.87%',
    }),
    placeholder: (provided) => ({
      ...provided,
      opacity: 0.8
    }),
    indicatorSeparator: () => ({
      display: 'none'
    }),
    indicatorsContainer: () => ({
      display: 'none'
    }),
    multiValue: (styles, { data }) => ({
      ...styles,
      backgroundColor: data.color === 'include' ? '#e0ffe0' : '#ffe0e0',
    }),
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: data.color === 'include' ? '#008000' : '#ff0000',
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: data.color === 'include' ? '#008000' : '#ff0000',
      "&:hover": {
        background: data.color === 'include' ? '#cbf8cb' : '#ffbbbb',
        color: data.color === 'include' ? '#008000' : '#ff0000',
      },
    }),
  });

  useLayoutEffect(() => {
    if ((recruiterId || jobOrderId) && !type) {
      if (defaultFilters?.skills?.length) {
        const filteredValues = filterDuplicates([...skillsESAggs!, ...defaultFilters?.skills])
        setContextSkillsESAggs(filteredValues)
      }

      if (defaultFilters?.searchText?.length) {
        const defaultJobTitles = defaultFilters?.searchText.map((item) => ({ label: item, value: item, color: 'include' }))
        const filteredValues = filterDuplicatesTitles([...defaultJobTitles, ...advanceSearch,])
        setAdvanceSearch(filteredValues)
      }
      setSelectedCountry(defaultFilters?.country!)
      if (defaultFilters?.state && defaultFilters?.country === 'US')
        setState(states_candidate_constant.find(({ label }) => defaultFilters!.state === label)!)

      setCity(defaultFilters?.city || '')
      setZipCode(defaultFilters?.zipCode || '')
      const radiusOptions = RADIUS_OPTIONS(defaultFilters?.country!)
      if (defaultFilters?.radius && city)
        setRadius(radiusOptions?.find(({ value }) => value === defaultFilters?.radius)!)
    }
  }, [defaultFilters, recruiterId, jobOrderId])

  useEffect(() => { refetch() }, [tabChange])
  // component lifecycle methods
  useEffect(() => {
    let statesOfSelectedCountry = State.getStatesOfCountry(
      countriesIsoCodeDto[selectedCountry]
    );

    let statesOfSelectedCountryInLabelValuePair: { label: string; value: string }[] = statesOfSelectedCountry.map(
      (v) => {
        return {
          label: v.name,
          value: v.isoCode,
        };
      }
    );
    setStateList(statesOfSelectedCountryInLabelValuePair);
  }, [selectedCountry]);

  const getCandidatesList = useCallback(() => {
    const searchText = advanceSearch.filter(({ color }) => color === 'include').map(({ value }) => value)
    const excludeSearch = advanceSearch.filter(({ color }) => color === 'exclude').map(({ value }) => value)

    console.log("searchText :: ", searchText)
    console.log("excludeSearch :: ", excludeSearch)
    _getCandidatesList({
      variables: {
        limit,
        excludeCandidateIds,
        recruiterId, type, jobOrderId,
        page: currentPage,
        searchText,
        excludeSearch,
        city: city,
        state: selectedState.label,
        zipCode,
        radius: radius.value,
        ...(includeJobTitle.value !== '0' && { includeJobTitle: includeJobTitle?.value }),
        skills: skillsESAggs,
        excludeSkills,
        country: selectedCountry || 'US'
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_getCandidatesList, city, defaultFilters, advanceSearch, currentPage, limit, radius.value, includeJobTitle, selectedCountry, selectedState.value, skillsESAggs, zipCode, searchCall]);

  useEffect(() => {
    if (!jobOrder || (recruiterId && jobOrderId && defaultFilters)) {
      getCandidatesList();
    }
  }, [limit, currentPage, getCandidatesList]);

  useEffect(() => {
    setCurrentPage(1)
    setLimit(10)
  }, [city, radius.value, selectedCountry, selectedState.value, skillsESAggs, zipCode, searchCall])


  const clearFilters = () => {
    setState({ label: '', value: '' });
    setCity('');
    setRadius({ label: '', value: '' });
    setAdvanceSearch([])
    setInputValue('')
    setZipCode('');
    setCurrentPage(1);
    setLimit(10);
    setExcludeSkills([])
    // setSelectedSkills([]);
    setContextSkillsESAggs([]);
  };

  const handleSkills = (event) => {
    let skills = skillsESAggs?.length ? skillsESAggs : [];
    if (event?.target?.checked) {
      let val = event?.target?.value;
      if (!skills.some((item) => item === val)) {
        skills.push(val);
      }
    } else {
      let val = event?.target?.value;
      skills = skills.filter((item) => item !== val);
    }

    setContextSkillsESAggs([...skills]);
  };

  const handleSkillsFromSearch = (skill: string, del: boolean = false, exclude: boolean = false) => {
    if (del && exclude) {
      setExcludeSkills(excludeSkills?.filter((val) => val !== skill))
      return
    }

    if (skillsESAggs?.length) {
      if (del) {
        setContextSkillsESAggs(skillsESAggs?.filter((val) => val !== skill))
        return
      }
      setContextSkillsESAggs([...skillsESAggs, skill])
    }
    else
      setContextSkillsESAggs([skill])
  }

  useEffect(() => {
    return () => {
      setContextSkillsESAggs([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Event Handlers to trigger the search API call when the user presses Enter
  const handleSearchEnterDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      setSearchCall(!searchCall);
      setCurrentPage(1);
    }
  };

  const handleSearchEnterUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      setSearchCall(!searchCall);
    }
  };

  return (
    <div className="admin-tabs team-tabs">
      {!jobOrder &&
        <>
          <GoBack />

          <div className="primaryHeading primaryHeaderSpacing">
            <h5 className="m-0">{CANDIDATE_SEARCH}</h5>
          </div>
        </>}

      <div className="candidate-search-wrap">
        <div className="bg-white dark-card">
          {!type && <div className="candidate-search-sfilter d-flex">
            <div className="search-filter d-block searchFilterResponsive p-3" style={{ width: '100%', marginRight: 22 }}>
              <div className="serach-for-candidate">
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <img src={SEARCH_IMG} alt="search" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Creatable
                    isClearable={false}
                    inputValue={inputValue}
                    onInputChange={(value, actionMeta) => {
                      if (actionMeta.action === 'input-change') {
                        setInputValue(value);
                      }
                    }}
                    value={advanceSearch}
                    multiValueRemove={true}
                    isMulti
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault()
                        setAdvanceSearch([...advanceSearch, { label: inputValue, value: inputValue, color: 'include' }])
                        setInputValue('')
                        setSearchCall(!searchCall)
                      }
                    }}
                    placeholder="Job Title..."
                    onChange={(val) => {
                      setAdvanceSearch(val.map((item) => item))
                      setSearchCall(!searchCall)
                    }}
                    styles={{ ...customSelectStyleNew(theme) }}
                  />

                  <InputGroupAddon addonType="prepend">
                    <button disabled={!inputValue} onClick={() => {
                      setAdvanceSearch([...advanceSearch, { label: inputValue, value: inputValue, color: 'include' }])
                      setInputValue('')
                      setSearchCall(!searchCall)
                    }} className='bg-transparent border-0 px-1'>
                      +                    </button>
                    <button disabled={!inputValue} onClick={() => {
                      setAdvanceSearch([...advanceSearch, { label: inputValue, value: inputValue, color: 'exclude' }])
                      setInputValue('')
                      setSearchCall(!searchCall)
                    }} className='bg-transparent border-0 px-1'>
                      -                    </button>
                  </InputGroupAddon>
                </InputGroup>
              </div>

            </div>

            <img src={BetaBadgeVersion} alt="beta" />
          </div>}

          <div className={!type ? "candidate-search-content" : ""}>
            <Row>

              <Col Col md={6} lg={4} xl={3}>
                <CandidateSearchFilters
                  selectedState={selectedState}
                  city={city}
                  zipCode={zipCode}
                  setCity={setCity}
                  setState={setState}
                  setZipCode={setZipCode}
                  radius={radius}
                  setRadius={setRadius}
                  includeJobTitle={includeJobTitle}
                  setIncludeJobTitle={setIncludeJobTitle}
                  getCandidatesList={getCandidatesList}
                  clearFilters={clearFilters}
                  aggregateResult={data?.allCandidatesAdvanceSearch?.aggsResult}
                  handleSkills={handleSkills}
                  handleSkillsFromSearch={handleSkillsFromSearch}
                  setExcludeSkills={setExcludeSkills}
                  excludeSkills={excludeSkills!}
                  setCurrentPage={setCurrentPage}
                  stateList={stateList}
                  setStateList={setStateList}
                  selectedCountry={selectedCountry}
                  setSelectedCountry={setSelectedCountry}
                  searchCall={searchCall}
                  setSearchCall={setSearchCall}
                  handleSearchEnterDown={handleSearchEnterDown}
                  handleSearchEnterUp={handleSearchEnterUp}
                  defaultSkills={defaultFilters?.skills!}
                  disabled={hiddenSideBar}
                />
              </Col>

              <Col className='col-md-6 col-lg-8 col-xl-9'>
                <div className={!hiddenSideBar ? "candidate-serach-wrap h-100" : ''}>
                  {!loading ? (
                    <CandidateSearchList
                      jobOrderId={jobOrderId}
                      refetch={refetch}
                      type={type}
                      data={data}
                      limit={limit}
                      setLimit={setLimit}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                    />
                  ) : (
                    <Loader />
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div >
  );
};

export default CandidateSearch;
