import { useLazyQuery } from "@apollo/client";
import { FC, useCallback, useEffect, useState } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import Loader from "../../components/layout/Loader";
import { PreSelectFilters } from "../../interfaces";
import { filterDuplicates } from "../../utils/helper";
import { AdvanceSearchProps } from "../agreements/interfaces";
import CandidateSearch from "../candidate/CandidateSearch";
import { GET_PRE_SELECT_VALUES_FOR_ADV_SEARCH } from "./gql";



const AdvanceSearch: FC<AdvanceSearchProps> = ({ jobOrderId, recruiterId, mustHaveSkills, defaultJobTitle }) => {
  const [activeTab, setActiveTab] = useState("1");
  const [defaultFilters, setDefaultFilters] = useState<PreSelectFilters>()

  const [getPreSelectValues, { loading, data }] = useLazyQuery(GET_PRE_SELECT_VALUES_FOR_ADV_SEARCH, {
    onCompleted: (data) => {
      const parsedJSON = JSON.parse(data?.getPreSelectJobOrderAdvSearch?.preSelectedAdvSearchFields)
      const searchText = parsedJSON?.searchText.length ? [...parsedJSON?.searchText] : []
      const skills = parsedJSON?.skills.length ? [...parsedJSON?.skills] : []
      setDefaultFilters({
        ...parsedJSON,
        searchText: filterDuplicates(searchText),
        ...(skills?.length && { skills: filterDuplicates(skills) })
      })
    },
    onError: () => {
      setDefaultFilters({
        searchText: filterDuplicates([defaultJobTitle]),
        ...(mustHaveSkills && { skills: filterDuplicates(mustHaveSkills) })
      })
    }
  })

  useEffect(() => {
    if (recruiterId && jobOrderId) {
      getPreSelectValues({ variables: { recruiterId, jobOrderId } })
    }
  }, [recruiterId, jobOrderId])


  const styles = useCallback((active: string) => ({
    fontSize: "14px",
    fontWeight: "500",
    color: "#6c757d",
    cursor: "pointer",
    padding: "8px 16px",
    borderRadius: "20px",
    textDecoration: "none",
    ...(active === activeTab && { backgroundColor: '#33475B', color: "#fff", })
  }), [activeTab])

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  if (!recruiterId || !jobOrderId || loading) return <Loader />

  return (
    <div>
      <Nav pills className="custom-tabs">
        <NavItem>
          <NavLink
            style={styles("1")}
            onClick={() => toggle("1")}
          >
            Search
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            style={styles("2")}
            onClick={() => toggle("2")}
          >
            Keep
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            style={styles("3")}
            onClick={() => toggle("3")}
          >
            Skipped
          </NavLink>
        </NavItem>
      </Nav>
      <div style={{ borderBottom: '1px solid #e4e4e4', marginTop: 10 }}></div>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <CandidateSearch jobOrder tabChange={activeTab} recruiterId={recruiterId} jobOrderId={jobOrderId} excludeCandidateIds
            defaultFilters={defaultFilters!}
          />
        </TabPane>
        <TabPane tabId="2">
          <CandidateSearch jobOrder hiddenSideBar
            type="Kept" defaultFilters={defaultFilters}
            tabChange={activeTab}
            jobOrderId={jobOrderId}
            recruiterId={recruiterId} />
        </TabPane>
        <TabPane tabId="3">
          <CandidateSearch jobOrder hiddenSideBar
            type="Skipped" defaultFilters={defaultFilters}
            tabChange={activeTab}
            jobOrderId={jobOrderId}
            recruiterId={recruiterId} />
        </TabPane>
      </TabContent>
    </div >
  );

}

export default AdvanceSearch;
