
import { FC, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { Col, Row } from "reactstrap";
import APPEND_ICON from "../../../assets/images/add-icon-group.svg";
import { EducationHistoryFormProps } from "../../../interfaces";
import { IEducationHistoryData, IEducationHistoryDataForm } from "../../../Pages/agreements/interfaces";
import CustomButton from "../../layout/CustomButton";
import SingleRowWithCheckField from "../basic-information/SingleRowWithCheckField";
import SelectAllCheckRow from "../common/SelectAllCheckRow";
import EducationHistorySingleItem from "./EducationHistory";
import { useMutation } from "@apollo/client";
import { SET_EDUCATION_INFO } from "../../../Pages/candidate/update-by-resume/gql";
import { CandidateEducationList, VIEW_CANDIDATE_ROUTE } from "../../../utils/constant";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

const EducationHistoryForm: FC<EducationHistoryFormProps> = ({
  candidateId, resumeId, handleBack, handleNext, newData, oldData, disabled }) => {
  const [selectAllChecks, setSelectAllChecks] = useState<boolean>(false);
  const history = useHistory();

  const [setEmploymentInfo] = useMutation(SET_EDUCATION_INFO, {
    onCompleted: () => {
      toast.success("education info updated successfully")
      history.push({ pathname: `${VIEW_CANDIDATE_ROUTE}/${candidateId}` })

    },
    onError: () => {
      toast.error("error while updating education info")
    }
  })

  const defaultAssignment = (data: IEducationHistoryData[]) => data?.map(({ endMonth, endYear, startMonth, startYear, degreeEarned, ...rest }) => (
    {
      ...(startMonth && { startMonth: { label: startMonth, value: startMonth } }),
      ...(startYear && { startYear: { label: startYear, value: startYear } }),
      ...(endMonth && { endMonth: { label: endMonth, value: endMonth } }),
      ...(endYear && { endYear: { label: endYear, value: endYear } }),
      ...(degreeEarned && { degreeEarned: CandidateEducationList?.find(({ label, value }) => label === degreeEarned || value === degreeEarned) }),
      label: rest.schoolName,
      ...rest
    }))

  const { control, handleSubmit, setValue, errors } = useForm<{ educationHistories: IEducationHistoryDataForm[] }>({
    defaultValues: {
      educationHistories: disabled ? defaultAssignment(oldData) : defaultAssignment(newData)
    },
  });

  const reassignValues = ({ endYear, endMonth, startYear, startMonth, degreeEarned, ...rest }: IEducationHistoryData, i: number) => {
    const objectKeys = Object.keys(rest)
    objectKeys?.forEach((key) => setValue(`educationHistories.${i}.${key}`, rest?.[`${key}`] || undefined))
    setValue(`educationHistories.${i}.degreeEarned`, CandidateEducationList?.find(({ label, value }) => label === degreeEarned || value === degreeEarned))
    setValue(`educationHistories.${i}.startMonth`, startMonth && { label: startMonth, value: startMonth })
    setValue(`educationHistories.${i}.startYear`, startYear && { label: startYear, value: startYear })
    setValue(`educationHistories.${i}.endMonth`, endMonth && { label: endMonth, value: endMonth })
    setValue(`educationHistories.${i}.endYear`, endYear && { label: endYear, value: endYear })
    setValue(`educationHistories.${i}.label`, rest.schoolName)
  }

  const { fields, append, remove } = useFieldArray<IEducationHistoryData>({
    control,
    name: "educationHistories",
  });

  const mapEduHistories = (educateHist: IEducationHistoryDataForm[]) => educateHist?.map((item, i) => ({
    schoolName: item?.schoolName,
    degreeEarned: item?.degreeEarned?.value,
    fieldOfStudy: item?.fieldOfStudy,
    startMonth: item?.startMonth?.value,
    startYear: item?.startYear?.value,
    endMonth: item?.endMonth?.value,
    endYear: item?.endYear?.value,
    id: oldData[i]?.id,
  }))

  const onSubmit = ({ educationHistories }: { educationHistories: IEducationHistoryDataForm[] }) => {
    console.log("Form Submitted:", mapEduHistories(educationHistories));
    setEmploymentInfo({
      variables: {
        educationInfo: {
          educationHistories: mapEduHistories(educationHistories), candidateId, resumeId
        }
      }
    })
  };


  return (
    <Col>
      <SelectAllCheckRow newData={!disabled} disabled={disabled || newData?.length > oldData?.length} selectAllChecks={selectAllChecks} setSelectAllChecks={setSelectAllChecks} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <div className="d-flex ">
            <div className="my-3 fs-13 fw-700">Education History</div>
            {!disabled && <img src={APPEND_ICON} alt="append" hidden className="ml-3" onClick={() => append({})} />}
          </div>
          {fields?.map(({ id, ...rest }, i) => (
            <SingleRowWithCheckField checkBox={!disabled} oldExists={i < oldData?.length} selectAll={selectAllChecks}
              onChecked={() => { reassignValues(oldData[i], i) }}
              onUnChecked={() => {
                if (!disabled) {
                  reassignValues(newData[i], i)
                }
              }}>
              <EducationHistorySingleItem control={control} disabled={disabled!} errors={errors}
                index={i} openCard={!rest?.schoolName || i === 0} header={rest?.schoolName!} remove={remove} size={fields.length} id={id!} />
            </SingleRowWithCheckField>
          ))}
        </div>

        {!disabled && (
          <Col className="mt-5">
            <Row
              className="jobOrderRevamp bg-transparent text-right">
              <Col xl='7' className="my-1">
                <CustomButton
                  buttonText="Previous"
                  buttonColor="secondary"
                  buttonType="button"
                  className="big-width mr-4"
                  buttonClick={handleBack}
                />
              </Col>
              <Col xl='3' className="my-1">
                <CustomButton
                  buttonText="Update Candidate"
                  buttonColor="primary"
                  buttonType="submit"
                  className="big-width mr-4"
                />
              </Col>
            </Row>
          </Col>
        )}
      </form>
    </Col>
  );
}

export default EducationHistoryForm;
