import { FC, useMemo, useState } from "react";
import { Col, Row } from "reactstrap";
import { ResumeUpdateHistorySingleItemProps } from "../../../interfaces";
import FormControlInput from "../common/FormControlInput";
import FormLabel from "../common/FormLabel";
import HistoryCollapse from "../common/HistoryCollapse";
import FormControlSelect from "../common/FormControlSelect";
import { CandidateEducationList, MonthFull } from "../../../utils/constant";
import { YEARS_TO_CURRENT } from "../../../utils/helper";



const EducationHistorySingleItem: FC<ResumeUpdateHistorySingleItemProps> = ({
  header, index, disabled, openCard, errors, control, remove }) => {
  const [headerDynamic, setHeaderDynamic] = useState<string>(header || '')
  const formError = useMemo(() => {
    return errors?.educationHistories?.length ? true : false
  }, [errors?.educationHistories?.length])

  return (
    <HistoryCollapse text={headerDynamic} open={openCard || formError} remove={() => { remove(index) }} disabled={true}
      control={control} name={`educationHistories.${index}.label`}>
      <div className='py-3'>
        <Col>
          <FormLabel text='School Name' required />
          <FormControlInput required='School Name is Required' name={`educationHistories.${index}.schoolName`}
            control={control} disabled={disabled!} onChange={(value) => { setHeaderDynamic(value) }} placeholder="School Name"
            error={errors?.educationHistories?.length && errors?.educationHistories[`${index}`]?.schoolName?.message} />
        </Col>

        <Col>
          <FormLabel text='Degree Earned' />
          <FormControlSelect
            options={CandidateEducationList} placeholder="Degree Earned" name={`educationHistories.${index}.degreeEarned`}
            control={control} disabled={disabled!} error={errors?.degreeEarned?.message} />
        </Col>

        <Col>
          <FormLabel text='Field Of Study' />
          <FormControlInput required={false} name={`educationHistories.${index}.fieldOfStudy`}
            control={control} disabled={disabled!} error={errors?.fieldOfStudy?.message} placeholder="Field Of Study" />
        </Col>

        <Col>
          <Row>
            <Col xs="6">
              <FormLabel text='Start Month' />
              <FormControlSelect options={MonthFull?.map((item) => ({ label: item, value: item }))}
                name={`educationHistories.${index}.startMonth`}
                control={control} disabled={disabled!} placeholder="start month"
                error={errors?.educationHistories?.length && errors?.educationHistories[`${index}`]?.startMonth?.message} />
            </Col>

            <Col xs="6">
              <FormLabel text='Start Year' />
              <FormControlSelect options={YEARS_TO_CURRENT?.map((item) => ({ label: item.toString(), value: item.toString() }))}
                name={`educationHistories.${index}.startYear`}
                control={control} disabled={disabled!} placeholder="start year"
                error={errors?.educationHistories?.length && errors?.educationHistories[`${index}`]?.startYear?.message} />
            </Col>
          </Row>
        </Col>

        <Col>
          <Row>
            <Col xs="6">
              <FormLabel text='End Month' />
              <FormControlSelect options={MonthFull?.map((item) => ({ label: item, value: item }))}
                name={`educationHistories.${index}.endMonth`}
                control={control} disabled={disabled!} placeholder="end month"
                error={errors?.educationHistories?.length && errors?.educationHistories[`${index}`]?.endMonth?.message} />
            </Col>

            <Col xs="6">
              <FormLabel text='End Year' />
              <FormControlSelect options={YEARS_TO_CURRENT?.map((item) => ({ label: item.toString(), value: item.toString() }))}
                name={`educationHistories.${index}.endYear`}
                control={control} disabled={disabled!} placeholder="end year"
                error={errors?.educationHistories?.length && errors?.educationHistories[`${index}`]?.endYear?.message} />
            </Col>
          </Row>
        </Col>


      </div>
    </HistoryCollapse>
  )
}

export default EducationHistorySingleItem;
