import { FC, useState, useEffect } from "react"
import { Col, Row } from "reactstrap"
import { SingleRowWithCheckFieldProps } from "../../../interfaces"
import FormLabel from "../common/FormLabel"

const SingleRowWithCheckField: FC<SingleRowWithCheckFieldProps> = ({
  oldExists, children, label, checkBox, required, onChecked, onUnChecked, selectAll }) => {
  const [check, setCheck] = useState<boolean>(false)

  useEffect(() => {
    console.log("selectAll,", selectAll)
    setCheck(selectAll!)
    if (selectAll)
      onChecked()
    else
      onUnChecked()
  }, [selectAll])

  return (
    <div>
      {label && <FormLabel text={label} required={required} />}
      <Col >
        <Row className='d-flex  align-items-start' >
          <Col xs={checkBox ? 10 : 12} className="p-0 m-0">
            {children}
          </Col>
          {checkBox && <Col xs={1} className='mt-1'>
            <label className={`checkbox-square ${label ? 'mb-4' : 'mt-4'}`}>
              <input type="checkbox" onChange={(e) => {
                if (e.target.checked) {
                  onChecked()
                } else {
                  onUnChecked()
                }
                setCheck(e.target.checked)
              }} checked={check} hidden={!oldExists} />
              <span className="checkmark" hidden={!oldExists}></span>
            </label>
          </Col>}
        </Row>
      </Col>
    </div>
  );
}

export default SingleRowWithCheckField;
