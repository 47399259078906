import { useLazyQuery } from "@apollo/client";
import { useEffect, useLayoutEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import GoBack from "../../../components/GoBack";
import Loader from "../../../components/layout/Loader";
import BasicInformationForm from "../../../components/update-by-resume/basic-information";
import EducationHistoryForm from "../../../components/update-by-resume/education-history";
import EmploymentHistoryForm from "../../../components/update-by-resume/employment-history";
import { EmploymentHistoryData, IEducationHistoryData, IResumeUpdateBasicInfoData } from "../../agreements/interfaces";
import { GET_BASIC_INFO, GET_EDUCATION_INFO, GET_EMPLOYMENT_INFO } from "./gql";

type BasicInfoQuery = {
  getBasicInfoUpdateData: {
    olderData: IResumeUpdateBasicInfoData,
    newData: IResumeUpdateBasicInfoData
  }
}

type EmploymentInfoQuery = {
  getEmploymentInfoUpdateData: {
    olderData: EmploymentHistoryData[],
    newData: EmploymentHistoryData[]
  }
}

type EducationInfoQuery = {
  getEducationInfoUpdateData: {
    olderData: IEducationHistoryData[],
    newData: IEducationHistoryData[]
  }
}


const Stepper = ({ steps, activeStep }) => {

  return (
    <Container className="stepper-container mt-4">
      <Row style={{ paddingLeft: 200 }} >
        {steps.map((label, index) => (
          <Col>
            <Row>
              <Col xs="aut" className="text-center">
                <div className={`step-circle ${activeStep > index ? "active" : ""}`}></div>
              </Col>
              {index < steps.length - 1 && <Col className={`my-2 step-line ${activeStep - 1 > index ? "active" : ""}`} ></Col>}
            </Row>
            <Row>
              <p className='step-label'>{label}</p>
            </Row>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

const UpdateByResume = ({ match }) => {
  const { candidateId, resumeId } = useParams<{ candidateId: string, resumeId: string, }>()
  const location = useLocation()
  const history = useHistory()
  const [activeStep, setActiveStep] = useState(Number(new URLSearchParams(location.search).get('activeStep')) || 1);
  const steps = ["Basic Info", "Employment", "Education"]
  const [getBasicInfo, { data: basicInfo, loading: basicInfoLoading }] = useLazyQuery<BasicInfoQuery>(GET_BASIC_INFO)
  const [getEmploymentInfo, { data: employmentInfo, loading: employmentInfoLoading }] = useLazyQuery<EmploymentInfoQuery>(GET_EMPLOYMENT_INFO)
  const [getEducationInfo, { data: educationInfo, loading: educationInfoLoading }] = useLazyQuery<EducationInfoQuery>(GET_EDUCATION_INFO)
  const toggleStep = (step: number) => {

    if (activeStep !== step) {
      setActiveStep(step);
      history.push(`${location?.pathname}?activeStep=${step}`);
    }
  };
  const handleNext = () => toggleStep(Math.min(activeStep + 1, steps.length));
  const handleBack = () => toggleStep(Math.max(activeStep - 1, 1));
  const renderActiveForm = () => {
    switch (activeStep) {
      case 1:
        return <Row>
          <div className="new-data-col ml-3 pr-4">
            <BasicInformationForm candidateId={candidateId} resumeId={resumeId} disabled handleBack={handleBack} handleNext={handleNext} oldData={basicInfo?.getBasicInfoUpdateData?.olderData!} newData={basicInfo?.getBasicInfoUpdateData?.newData!} />
          </div>
          <BasicInformationForm candidateId={candidateId} resumeId={resumeId} handleBack={handleBack} handleNext={handleNext} oldData={basicInfo?.getBasicInfoUpdateData?.olderData!} newData={basicInfo?.getBasicInfoUpdateData?.newData!} />
        </Row>
      case 2:
        return <Row>
          <div className="new-data-col ml-3 pr-4">
            <EmploymentHistoryForm candidateId={candidateId} resumeId={resumeId} disabled handleBack={handleBack} handleNext={handleNext} oldData={employmentInfo?.getEmploymentInfoUpdateData?.olderData!} newData={employmentInfo?.getEmploymentInfoUpdateData?.newData!} />
          </div>
          <EmploymentHistoryForm candidateId={candidateId} resumeId={resumeId} handleBack={handleBack} handleNext={handleNext} oldData={employmentInfo?.getEmploymentInfoUpdateData?.olderData!} newData={employmentInfo?.getEmploymentInfoUpdateData?.newData!} />
        </Row >

      case 3:
        return <Row>
          <div className="new-data-col ml-3 pr-4">
            <EducationHistoryForm candidateId={candidateId} resumeId={resumeId} disabled handleBack={handleBack} handleNext={handleNext} oldData={educationInfo?.getEducationInfoUpdateData?.olderData!} newData={educationInfo?.getEducationInfoUpdateData?.newData!} />
          </div>
          <EducationHistoryForm candidateId={candidateId} resumeId={resumeId} handleBack={handleBack} handleNext={handleNext} oldData={educationInfo?.getEducationInfoUpdateData?.olderData!} newData={educationInfo?.getEducationInfoUpdateData?.newData!} />
        </Row>

      default:
        return ''
    }
  }

  useLayoutEffect(() => {
    if (activeStep === 1)
      getBasicInfo({ variables: { candidateId, resumeId } })
    else if (activeStep === 2)
      getEmploymentInfo({ variables: { candidateId, resumeId } })
    else if (activeStep === 3)
      getEducationInfo({ variables: { candidateId, resumeId } })
  }, [activeStep])


  return (
    <div className="admin-tabs team-tabs customUploadHover">
      <GoBack />
      <div className="primaryHeading"><h5 className="m-0">Edit Candidate</h5></div>
      <div className="revampContainer my-4 px-4">
        <h3 className="mt-2">Update Candidate</h3>
        <div className="border-divider mb-4" />
        <Stepper activeStep={activeStep} steps={steps} />
        {(basicInfoLoading || employmentInfoLoading || educationInfoLoading) ? <Loader /> :
          (basicInfo?.getBasicInfoUpdateData ||
            employmentInfo?.getEmploymentInfoUpdateData ||
            educationInfo?.getEducationInfoUpdateData) && renderActiveForm()}
      </div>
    </div>
  );
}

export default UpdateByResume

