import Admin from '../Pages/admin/Admin';
import UsersComponent from '../Pages/admin/AdminUsers';
import LoginAs from '../Pages/admin/persist-user/LoginAs';
import AddEditTeam from '../Pages/admin/team/EditTeam';
import TeamComponent from '../Pages/admin/team/Teams';
import ViewTeam from '../Pages/admin/team/ViewTeam';
import AddAndEditUser from '../Pages/admin/users/AddAndEditUser';
import AllAgreements from '../Pages/agreements/AllAgreements';
import CreateUpdateAgreement from '../Pages/agreements/CreateUpdateAgreement';
import ViewAgreement from '../Pages/agreements/ViewAgreement';
import AllCompanies from '../Pages/company/AllCompanies';
import CreateUpdateCompany from '../Pages/company/CreateCompany';
import UpdateCompany from '../Pages/company/UpdateCompany';
import ViewCompany from '../Pages/company/ViewCompany';
import AllContacts from '../Pages/contacts/AllContacts';
import CreateContact from '../Pages/contacts/CreateContact';
import UpdateContact from '../Pages/contacts/UpdateContact';
import ViewContacts from '../Pages/contacts/ViewContacts';
import CustomiseDashboard from '../Pages/dashboard/CustomiseDashboard';
import Main from '../Pages/dashboard/Main';
import AllNotifications from '../Pages/notifications/AllNotifications';
import RealreppDocuments from '../Pages/documents/RealreppDocuments';
import MergeCompanies from '../Pages/merge-company/MergeCompanies';
import Reports from '../Pages/Reports/Reports';
import TeamsReport from '../Pages/Reports/TeamsReport';
import SettingsComponent from '../Pages/settings/Settings';
import MyStats from '../Pages/Stats';
import MergeContacts from '../Pages/merge-contact/MergeContact';
import AllJobOrders from '../Pages/job-order/AllJobOrders';
import CreateJobOrder from '../Pages/job-order/CreateJobOrder';
import UpdateJobOrder from '../Pages/job-order/UpdateJobOrder';
import AllOrgChart from '../Pages/org-chart/AllOrgChart';
import ViewJobOrder from '../Pages/job-order/ViewJobOrder';
import AllCandidates from '../Pages/candidate/AllCandidates';
import CreateCandidate from '../Pages/candidate/CreateCandidate';
import ViewCandidate from '../Pages/candidate/ViewCandidate';
import UpdateCandidate from '../Pages/candidate/UpdateCandidate';
import JobApplicants from '../Pages/job-order/JobApplicants';
import JobApplicantView from '../Pages/job-order/JobApplicantView';
import ClientDocumentPreviewAndNotes from '../Pages/job-order/job-order-components/ClientDocumentPreviewAndNotes';
import CandidateDocPreview from '../Pages/candidate/CandidateDocPreview';
import AllSequences from '../Pages/interseller/sequences/AllSequences';
import SequenceTabs from '../Pages/interseller/sequences/sequence-components/SequenceTabs';
import ContactsAddedToday from '../Pages/interseller/contacts/contacts-components/ContactsAddedToday';
import PipelineListing from '../Pages/candidate-pipeline/PipelineListing';
import PipelineDetail from '../Pages/candidate-pipeline/PipelineDetail';
import CandidateSubmission from '../Pages/candidate-submission/CandidateSubmission';
import JobsListing from '../Pages/CloudSearch/Jobs/JobsListing';
import SearchResultIndex from '../Pages/CloudSearch/SearchResults/SearchResultIndex';
import NotesSearchIndex from '../Pages/NotesSearch/NotesSearchIndex';
import FolderListing from '../Pages/recruiter-folder/FolderListing';
import FolderDetail from '../Pages/recruiter-folder/FolderDetail';
import PlacementManagement from '../Pages/AccountingDashboard/PlacementManagement';
import BillingSheet from '../Pages/AccountingDashboard/BillingSheet';
import NewAssignment from '../Pages/AccountingDashboard/NewAssignment';
import LegacyAgreements from '../Pages/agreements/Legacy';
import CreateLegacyAgreement from '../Pages/agreements/CreateLegacyAgreement';
import AccountingPlacementManagement from '../Pages/AccountingDashboard/AccountingPlacementManagement';
import ViewPlacementManagement from '../Pages/candidate-pipeline/placements/view/ViewPlacementManagement';
import AccountingPlacementView from '../Pages/candidate-pipeline/placements/view/ViewAccountingPlacement';
import IndexUpdatePlacement from '../Pages/candidate-pipeline/placements/create/IndexUpdatePlacement';
import PrivateListingCandidate from '../Pages/recruiter-folder/PrivateCandidateListing';
import UnAuthorized from '../components/UnAuthorized';
import DocusSign from '../Pages/docuSignOauth/DocuSign';
import MergeCandidates from '../Pages/merge-candidate/MergeCandidates';
import CandidateSearch from '../Pages/candidate/CandidateSearch';
import CompanyReport from '../Pages/Reports/CompanyReport';
import {
  ACCOUNTING_DASHBOARD_ROUTE, ADMIN_IMPORT_CSV, AGREEMENTS_ROUTE, CANDIDATE_OWNERSHIP_REPORT_ROUTE, CHATGPT_TEMPLATE_ROUTE, COMPANY_OWNERSHIP_REPORT_ROUTE, CONTACT_DETAIL_ROUTE, CREATE_JOB_ORDER_ROUTE,
  DASHBOARD_ROUTE,
  EMAIL_TEMPLATES_ROUTE,
  GOAL_SHEET_REPORT,
  IMPORT_CSV_ROUTE,
  JOB_APPLICANTS_ROUTE, MODULE_ROUTE, PLACEMENT_MANAGEMENT_ROUTE, RECRUITER_DEAL_TEMPLATES_ROUTE, RECRUITER_FOLDERS_ROUTE, REQUESTS_ROUTE, ROLES, SETTINGS_ROUTE, SYNONYM_ROUTE, TEMP_BILLING_IMPORTS_ROUTE, TJ_LOOKUP_SETTING_ROUTE, UPDATE_CANDIDATE_BY_RESUME, UPDATE_CANDIDATE_ROUTE,
  UPDATE_COMPANY_ROUTE, USER_ACTIVITY_REPORT_ROUTE, VIEW_ACCOUNTING_DASHBOARD_ROUTE, VIEW_AGREEMENT_ROUTE, VIEW_CANDIDATE_ROUTE,
  VIEW_JOB_APPLICANT_ROUTE, VIEW_JOB_ORDER_ROUTE, VIEW_PLACEMENT_MANAGEMENT_ROUTE, VIEW_RECRUITER_FOLDER_ROUTE
} from './constant';
import Requests from '../Pages/requests';
import TjLookupSetting from '../Pages/interseller/tjlookup-setting';
import CompanyOwnershipReport from '../Pages/settings/companyOwnershipReport';
import Synonyms from '../Pages/synonyms';
import Module from '../Pages/moduleAccess-settings';
import ChatGPT_Templates from '../Pages/chatGPT-templates';
import AllAlertOrWarnings from '../Pages/alert-warnings/AllAlertOrWarnings';
import AllJobList from '../Pages/job-list';
import ImportCSV from '../Pages/import-csv';
import GoalSheetReport from '../Pages/goal-sheet-report';
import AdminImport from '../Pages/import-csv/AdminImports';
import CandidateOwnerShipReport from '../Pages/settings/candidateOwnerShipReport';
import UserActivityReports from '../Pages/settings/userActivityReport';
import EmailTemplates from '../Pages/email-templates';
import RecruitersDeal from '../Pages/admin/recruiters-deal';
import RecruitersDealTemplates from '../Pages/recruiter-deal-templates';
import CandidateSearchDev from '../Pages/candidate/CandidateSearchDev';
import TempBillingImports from '../Pages/temp-billing-reports';
import UpdateByResume from '../Pages/candidate/update-by-resume';

const privateRoutes = [
  {
    path: DASHBOARD_ROUTE,
    component: Main,
    exact: true,
    /*
    [IMPORTANT]
    Do not provide roles array to /dashboard route,
     because we are redirecting every route at dashboard if access fails.
    */
  },
  {
    path: '/admin/teams',
    exact: true,
    component: TeamComponent,
    roles: [ROLES.ADMIN],
  },
  {
    path: '/admin/persist',
    exact: true,
    component: LoginAs,
    // roles: [ROLES.ADMIN, ROLES.MANAGER, ROLES.RECRUITER],
  },
  {
    path: '/admin/stats/:key',
    exact: true,
    component: MyStats,
    // roles: [ROLES.ADMIN, ROLES.MANAGER, ROLES.RECRUITER],
  },
  {
    path: '/admin',
    exact: true,
    component: Admin,
    roles: [ROLES.ADMIN, ROLES.ACCOUNTING_MANAGER, ROLES.EXECUTIVE, ROLES.ACCOUNTING_COORDINATOR],
  },
  {
    path: `${DASHBOARD_ROUTE}/customise`,
    exact: true,
    component: CustomiseDashboard,
  },

  {
    path: '/admin/users',
    exact: true,
    component: UsersComponent,
    roles: [ROLES.ADMIN],
  },

  {
    path: '/admin/users/recruiter-deal/:id',
    exact: true,
    component: RecruitersDeal,
    roles: [ROLES.ADMIN],
  },

  {
    path: '/admin/view-teams/:id',
    exact: true,
    component: ViewTeam,
    roles: [ROLES.ADMIN],
  },
  {
    path: '/admin/teams/edit/:id',
    exact: true,
    component: AddEditTeam,
    roles: [ROLES.ADMIN],
  },
  {
    path: '/admin/teams-report',
    exact: true,
    component: TeamsReport,
    // roles: [ROLES.ADMIN, ROLES.MANAGER, ROLES.EXECUTIVE],
  },
  {
    path: '/admin/edit-user/:id',
    exact: true,
    roles: [ROLES.ADMIN],
    component: AddAndEditUser,
  },
  {
    path: '/admin/add-user',
    exact: true,
    component: AddAndEditUser,
    roles: [ROLES.ADMIN],
  },
  {
    path: SETTINGS_ROUTE,
    exact: true,
    component: SettingsComponent,
  },
  {
    path: '/companies',
    exact: true,
    component: AllCompanies,
  },
  {
    path: '/reports',
    exact: true,
    component: Reports,
  },
  {
    path: '/create-company',
    exact: true,
    component: CreateUpdateCompany,
  },
  {
    path: `${UPDATE_COMPANY_ROUTE}/:id`,
    exact: true,
    component: UpdateCompany,
  },
  {
    path: '/company-details/:id',
    exact: true,
    component: ViewCompany,
  },
  {
    path: `${CONTACT_DETAIL_ROUTE}/:id`,
    exact: true,
    component: ViewContacts,
  },
  {
    path: '/contacts',
    exact: true,
    component: AllContacts,
  },
  {
    path: '/create-contacts',
    exact: true,
    component: CreateContact,
  },
  {
    path: AGREEMENTS_ROUTE,
    exact: true,
    component: AllAgreements,
  },
  {
    path: '/legacy-agreements',
    exact: true,
    component: LegacyAgreements,
    roles: [ROLES.ADMIN, ROLES.EXECUTIVE],
  },
  {
    path: `${VIEW_AGREEMENT_ROUTE}/:id`,
    exact: true,
    component: ViewAgreement,
  },

  {
    path: '/create-agreement',
    exact: true,
    component: CreateUpdateAgreement,
  },
  {
    path: '/create-legacy-agreement',
    exact: true,
    component: CreateLegacyAgreement,
  },
  {
    path: '/update-agreement/:id',
    exact: true,
    component: CreateUpdateAgreement,
  },

  {
    path: '/update-company-contact/:id',
    exact: true,
    component: UpdateContact,
  },
  {
    path: '/notifications',
    exact: true,
    component: AllNotifications,
  },
  {
    path: '/alerts-warnings',
    exact: true,
    component: AllAlertOrWarnings,
  },
  {
    path: '/merge-company',
    exact: true,
    roles: [ROLES.ADMIN],
    component: MergeCompanies,
  },
  {
    path: '/merge-candidate',
    exact: true,
    roles: [ROLES.ADMIN],
    component: MergeCandidates,
  },
  {
    path: '/merge-contacts',
    exact: true,
    roles: [ROLES.ADMIN],
    component: MergeContacts,
  },
  {
    path: '/realrepp-documents',
    exact: true,
    component: RealreppDocuments,
  },
  {
    path: '/job-orders',
    exact: true,
    component: AllJobOrders,
  },
  {
    path: CREATE_JOB_ORDER_ROUTE,
    exact: true,
    component: CreateJobOrder,
  },
  {
    path: `${VIEW_JOB_ORDER_ROUTE}/:id`,
    exact: true,
    component: ViewJobOrder,
  },
  {
    path: '/update-job-order/:id',
    exact: true,
    component: UpdateJobOrder,
  },
  {
    path: '/client-document-job-order/:id',
    exact: true,
    component: ClientDocumentPreviewAndNotes,
  },
  {
    path: '/org-chart',
    exact: true,
    component: AllOrgChart,
  },
  {
    path: '/leaderboard',
    exact: true,
    component: CompanyReport,
  },
  {
    path: '/candidates',
    exact: true,
    component: AllCandidates,
  },
  //TimeToFill
  // {
  //   path: '/time-to-fill',
  //   exact: true,
  //   component: TimeToFill,
  // },
  //Job List
  {
    path: '/job-list',
    exact: true,
    component: AllJobList,
  },
  {
    path: '/create/candidate',
    exact: true,
    component: CreateCandidate,
  },
  {
    path: `${VIEW_CANDIDATE_ROUTE}/:id`,
    exact: true,
    component: ViewCandidate,
  },
  {
    path: `${UPDATE_CANDIDATE_ROUTE}/:id`,
    exact: true,
    component: UpdateCandidate,
  },
  {
    path: JOB_APPLICANTS_ROUTE,
    exact: true,
    component: JobApplicants,
  },
  {
    path: `${VIEW_JOB_APPLICANT_ROUTE}/:id`,
    exact: true,
    component: JobApplicantView,
  },
  {
    path: '/view/resume/:id',
    exact: true,
    component: CandidateDocPreview,
  },
  {
    path: '/sequences',
    exact: true,
    component: AllSequences,
  },
  {
    path: '/sequences/:id',
    exact: true,
    component: SequenceTabs,
  },
  {
    path: '/contacts-added-today',
    exact: true,
    component: ContactsAddedToday,
  },
  {
    path: '/candidate-pipeline',
    exact: true,
    component: PipelineListing,
  },
  {
    path: '/candidate-pipeline/view/:id',
    exact: true,
    component: PipelineDetail,
  },
  {
    path: '/candidate-submission/:id',
    exact: true,
    component: CandidateSubmission,
  },
  {
    path: '/advance-search-job-candidate',
    exact: true,
    component: JobsListing,
  },
  {
    path: '/advance-search-job-candidate/:id/results',
    exact: true,
    component: SearchResultIndex,
  },
  {
    path: '/notes-es',
    exact: true,
    component: NotesSearchIndex,
  },
  {
    path: RECRUITER_FOLDERS_ROUTE,
    exact: true,
    component: FolderListing,
  },
  {
    path: `${VIEW_RECRUITER_FOLDER_ROUTE}/:id`,
    exact: true,
    component: FolderDetail,
  },
  {
    path: ACCOUNTING_DASHBOARD_ROUTE,
    exact: true,
    component: AccountingPlacementManagement,
    roles: [ROLES.ACCOUNTING_MANAGER, ROLES.EXECUTIVE],
  },
  {
    path: PLACEMENT_MANAGEMENT_ROUTE,
    exact: true,
    component: PlacementManagement,
  },
  {
    path: `${VIEW_ACCOUNTING_DASHBOARD_ROUTE}/:id/newassignment`,
    exact: true,
    component: NewAssignment,
    roles: [ROLES.ACCOUNTING_MANAGER, ROLES.ACCOUNTING_COORDINATOR],
  },
  {
    path: `${VIEW_ACCOUNTING_DASHBOARD_ROUTE}/:id/billingsheet`,
    exact: true,
    component: BillingSheet,
    roles: [ROLES.ACCOUNTING_MANAGER, ROLES.ACCOUNTING_COORDINATOR],
  },
  // {
  //   path: "/candidate-pipelining/interview",
  //   exact: true,
  //   component: InterviewList,
  // },

  // {
  //   path: "/candidate-pipelining/interview/details",
  //   exact: true,
  //   component: InterviewDetail,
  // },

  // {
  //   path: "/candidate-pipelining/offers/:id",
  //   exact: true,
  //   component: OfferList,
  // },

  {
    path: '/candidate-pipelining/placements/update',
    exact: true,
    component: IndexUpdatePlacement,
  },

  {
    path: `${VIEW_PLACEMENT_MANAGEMENT_ROUTE}/:id`,
    exact: true,
    component: ViewPlacementManagement,
  },
  {
    path: `${VIEW_ACCOUNTING_DASHBOARD_ROUTE}/:id`,
    exact: true,
    component: AccountingPlacementView,
    roles: [ROLES.ACCOUNTING_MANAGER],
  },
  {
    path: '/private-candidates',
    exact: true,
    component: PrivateListingCandidate,
    roles: [ROLES.EXECUTIVE, ROLES.ADMIN, ROLES.HR],
  },
  {
    path: '/candidate-search',
    exact: true,
    component: CandidateSearch,
  },
  {
    path: '/candidate-search-dev',
    exact: true,
    component: CandidateSearchDev,
  },
  {
    path: '/un-authorized',
    exact: true,
    component: UnAuthorized,
  },
  {
    path: '/docusign/callback',
    exact: true,
    component: DocusSign,
  },
  {
    path: REQUESTS_ROUTE,
    exact: true,
    component: Requests,
  },
  {
    path: TJ_LOOKUP_SETTING_ROUTE,
    exact: true,
    component: TjLookupSetting,
  },
  {
    path: COMPANY_OWNERSHIP_REPORT_ROUTE,
    exact: true,
    component: CompanyOwnershipReport,
  },
  {
    path: CANDIDATE_OWNERSHIP_REPORT_ROUTE,
    exact: true,
    component: CandidateOwnerShipReport,
  },
  {
    path: SYNONYM_ROUTE,
    exact: true,
    component: Synonyms,
    roles: [ROLES.EXECUTIVE, ROLES.ADMIN],
  },
  {
    path: MODULE_ROUTE,
    exact: true,
    component: Module,
  },
  {
    path: CHATGPT_TEMPLATE_ROUTE,
    exact: true,
    component: ChatGPT_Templates,
  },
  {
    path: IMPORT_CSV_ROUTE,
    exact: true,
    component: ImportCSV,
  },
  {
    path: TEMP_BILLING_IMPORTS_ROUTE,
    exact: true,
    component: TempBillingImports
  },
  {
    path: `${GOAL_SHEET_REPORT}/:id`,
    exact: true,
    component: GoalSheetReport
  },
  {
    path: ADMIN_IMPORT_CSV,
    exact: true,
    component: AdminImport,
  },
  {
    path: USER_ACTIVITY_REPORT_ROUTE,
    exact: true,
    component: UserActivityReports,
  },
  {
    path: EMAIL_TEMPLATES_ROUTE,
    exact: true,
    component: EmailTemplates,
  },

  {
    path: RECRUITER_DEAL_TEMPLATES_ROUTE,
    exact: true,
    component: RecruitersDealTemplates,
  },
  {
    path: `${UPDATE_CANDIDATE_BY_RESUME}/:candidateId/:resumeId`,
    exact: true,
    component: UpdateByResume
  },
];

export default privateRoutes;
