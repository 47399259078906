import { useCallback, useContext, useState } from 'react';
import CandidateDetailDrawer from '../../../components/CandidateDetailDrawer';
import Checkbox from '../../../components/Checkbox';
import { countryWiseHeaderLocation } from '../../../utils/helper';
import { Col, Modal } from 'reactstrap';
import Preview from '../../job-order/job-order-components/document-preview/Preview';
import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_SIGNED_URL } from '../../settings/gql';
import CustomButton from '../../../components/layout/CustomButton';
import ConfirmPopUp from '../../../components/ConfirmPopUp';
import { ADD_JOB_ORDER_ADVANCE_SEARCH_OPTION, APPLY_TO_JOB, DEL_JOB_ORDER_ADVANCE_SEARCH_OPTION } from '../../job-order/gql';
import { toast } from 'react-toastify';
import { AppContext } from '../../../context';
import { TOAST_COMMON_MSG } from '../../../utils/constant';

const CandidateSearchItem = ({
  candidate,
  geoLocDistance,
  selectedCandidates,
  setSelectedCandidates,
  setAllSelected,
  key,
  jobOrderId,
  refetch,
  type
}) => {
  const [showCandidateDetails, setShowCandidateDetails] = useState<boolean>(false);
  const [delSearchSettingItem, { loading }] = useMutation(DEL_JOB_ORDER_ADVANCE_SEARCH_OPTION, {
    onCompleted: () => {
      toast.success(TOAST_COMMON_MSG.REMOVED_SUCCESS)
      refetch()
    },
    onError: () => {
      toast.error(TOAST_COMMON_MSG.REMOVED_ERROR)
    }
  })

  const [onApplyMutation, { loading: applying }] = useMutation(APPLY_TO_JOB);


  const [addSearchSetting, { loading: adding }] = useMutation(ADD_JOB_ORDER_ADVANCE_SEARCH_OPTION, {
    onCompleted: () => {
      toast.success(TOAST_COMMON_MSG.ADDED_SUCCESS)
      refetch()
    },
    onError: () => {
      toast.error(TOAST_COMMON_MSG.ADDED_ERROR)
    }
  })

  const [isPreview, setIsPreview] = useState<boolean>(false);
  const [createApplication, setCreateApplication] = useState<boolean>(false);
  const [resumeData, setResumeData] = useState<{ name: string, url: string }>();
  const candidateDetailToggle = () => {
    setShowCandidateDetails(!showCandidateDetails);
  };
  const { user } = useContext(AppContext)
  const [getSignedURL] = useLazyQuery(GET_SIGNED_URL);
  const manageSelection = (e: any) => {
    if (e.target.checked) {
      setSelectedCandidates([...selectedCandidates, candidate]);
    } else {
      const ids = selectedCandidates?.filter(
        (id: any) => id?.id !== candidate?.id
      );
      setSelectedCandidates(ids);
      setAllSelected(false);
    }
  };

  const SignedUrl = useCallback(async (url) => {
    const { data } = await getSignedURL({ variables: { url } })
    const { getSignedUrl } = data || {};
    const { signedUrl } = getSignedUrl || {};
    return signedUrl;
  }, [getSignedURL])

  const isCurrentJobApplicant = () => {
    const found = candidate?.jobApplicants?.find(({ JobOrder }) => jobOrderId === JobOrder?.id)
    if (found)
      return 'Yes'
    return undefined
  }

  const applicationToggle = () => setCreateApplication(!createApplication)
  return (
    <Col xs='12'>
      <div className="candidate-search-item mb-4" key={key}>
        <div className="bg-whiteseven px-3 py-4 candidate-searchitem-box light-dark">
          <div className={jobOrderId ? "d-flex justify-content-around " : "d-flex"}>
            <div className="candidate-searchitem-checkbox">
              <Checkbox
                parentClass="checkbox-square"
                candidateId={candidate?.id}
                selectedCandidates={selectedCandidates}
                onChange={(e: any) => manageSelection(e)}
              />
            </div>
            <div className="candidate-searchitem-item flex-1 pl-3 w-70">
              <div className="d-flex align-items-start mb-3 justify-content-center">
                <div className="fixed-width text-right">
                  <h6 className="text-sm mb-0 font-weight-bold text-blackten">
                    Name:
                  </h6>
                </div>

                <div className="flex-1 w-100 pl-3">
                  <span
                    className=" text-primary curser-pointer"
                    onClick={() => candidateDetailToggle()}
                  >
                    <p className="text-sm mb-0 text-bluelink font-weight-normal">
                      {candidate?.fullName}
                    </p>
                  </span>

                  <p className="text-sm mb-0 text-blackten font-weight-normal">
                    {countryWiseHeaderLocation(candidate?.countryCode, candidate?.city, candidate?.stateCode)}
                  </p>
                </div>
              </div>
              {candidate?.resume && candidate?.resume[0]?.originalUrl &&
                <div className="d-flex align-items-start mb-3 justify-content-center">
                  <div className="fixed-width text-right">
                    <h6 className="text-sm mb-0 font-weight-bold text-blackten">
                      Resume:
                    </h6>
                  </div>

                  <div className="flex-1 w-100 pl-3">
                    <span
                      className=" text-primary curser-pointer" onClick={async () => {
                        const url = await SignedUrl(candidate?.resume[0]?.originalUrl)
                        setResumeData({
                          name: candidate?.resume[0]?.resumeFileName, url
                        })
                        setIsPreview(true)
                      }} >
                      <p className="text-sm mb-0 text-bluelink font-weight-normal">
                        {candidate?.resume[0]?.resumeFileName}
                      </p>
                    </span>
                  </div>
                </div>}

              {candidate && candidate?.jobTitle && (
                <div className="d-flex align-items-center mb-3">
                  <div className="fixed-width text-right">
                    <h6 className="text-sm mb-0 font-weight-bold text-blackten">
                      Job Title:
                    </h6>
                  </div>

                  <div className="flex-1 w-100 pl-3">
                    <p className="text-sm mb-0 font-weight-normal text-blackten">
                      {candidate?.jobTitle}
                    </p>
                  </div>
                </div>
              )}

              {candidate && geoLocDistance >= 0 && (
                <div className="d-flex align-items-center mb-3">
                  <div className="fixed-width text-right">
                    <h6 className="text-sm mb-0 font-weight-bold text-blackten">
                      Distance:
                    </h6>
                  </div>

                  <div className="flex-1 w-100 pl-3">
                    <p className="text-sm mb-0 font-weight-normal text-blackten">
                      {geoLocDistance} miles
                    </p>
                  </div>
                </div>
              )}

              {candidate && candidate?.employmentHistory?.length > 0 && (
                <div className="d-flex align-items-start mb-3">
                  <div className="fixed-width text-right">
                    <h6 className="text-sm mb-0 font-weight-bold text-blackten">
                      Experience:
                    </h6>
                  </div>

                  <div className="flex-1 w-100 pl-3">
                    {candidate &&
                      candidate?.employmentHistory
                        ?.slice(0, 2)
                        ?.map((experience, index) => {
                          return (
                            <p
                              key={index}
                              className={`text-sm ${candidate?.employmentHistory?.length - 1 ===
                                index
                                ? 'mb-0'
                                : 'mb-1'
                                } font-weight-normal text-blackten`}
                            >
                              {experience?.jobTitle}
                              {' at '}
                              {experience?.companyName}{' '}
                              <span style={{ fontStyle: 'italic' }}>
                                {experience?.startedAt?.substring(0, 4)}
                                {' - '}
                                {experience?.endedAt?.substring(0, 4) ||
                                  'present'}
                              </span>
                            </p>
                          );
                        })}
                  </div>
                </div>
              )}

              {candidate && candidate?.candidateEducation && (
                <div className="d-flex align-items-center mb-3">
                  <div className="fixed-width text-right">
                    <h6 className="text-sm mb-0 font-weight-bold text-blackten">
                      Education:
                    </h6>
                  </div>

                  <div className="flex-1 w-100 pl-3">
                    <p className="text-sm mb-0 font-weight-normal text-blackten">
                      {candidate?.candidateEducation?.schoolName}
                      {candidate?.candidateEducation?.fieldOfStudy && ', '}
                      {candidate?.candidateEducation?.fieldOfStudy}
                    </p>
                  </div>
                </div>
              )}

              {candidate && candidate?.recruiterOwner && (
                <div className="d-flex align-items-center mb-3">
                  <div className="fixed-width text-right">
                    <h6 className="text-sm mb-0 font-weight-bold text-blackten">
                      Owner:
                    </h6>
                  </div>

                  <div className="flex-1 w-100 pl-3">
                    <p className="text-sm mb-0 font-weight-normal text-blackten">
                      {`${candidate?.recruiterOwner?.firstName} ${candidate?.recruiterOwner?.lastName}`}
                    </p>
                  </div>
                </div>
              )}

              <div className="d-flex align-items-start">
                <div className="fixed-width text-right">
                  <h6 className="text-sm mb-0 font-weight-bold text-blackten">
                    Contact:
                  </h6>
                </div>

                <div className="flex-1 w-100 pl-3">
                  <p className="text-sm mb-2 font-weight-normal text-bluelink">
                    {candidate?.emails?.length
                      ? candidate?.emails?.find((val) => val?.isPrimary)?.email
                      : 'NA'}
                  </p>
                  <p className="text-sm mb-0 font-weight-normal text-blackten">
                    {candidate?.phones?.length
                      ? candidate?.phones?.find((val) => val?.isPrimary)?.email
                      : 'NA'}
                  </p>
                </div>
              </div>
              {isCurrentJobApplicant() && (

                <div className="d-flex align-items-center mb-3">
                  <div className="fixed-width text-right">
                    <h6 className="text-sm mb-0 font-weight-bold text-blackten">
                      Is Applicant:
                    </h6>
                  </div>

                  <div className="flex-1 w-100 pl-3">
                    <p className="text-sm mb-0 font-weight-normal text-blackten">
                      {isCurrentJobApplicant()}
                    </p>
                  </div>
                </div>
              )}
            </div>
            {jobOrderId &&
              <div
                className='d-flex flex-column justify-content-center align-items-center'>
                {type !== 'Skipped' &&
                  <CustomButton
                    buttonClick={() => setCreateApplication(true)}
                    buttonText={'Apply'}
                    className='my-1' />}
                {type &&
                  <CustomButton
                    buttonClick={() => {
                      delSearchSettingItem({
                        variables: {
                          candidateId: candidate?.id,
                          recruiterId: user?.id,
                          jobOrderId,
                          type
                        }
                      })
                    }}
                    buttonText='Remove From List'
                    className="graydark-button my-1" />}

                {!type &&
                  <>
                    <CustomButton buttonText='Keep' className='my-1' buttonClick={() => {
                      addSearchSetting({
                        variables: {
                          input: {
                            candidateId: candidate?.id,
                            recruiterId: user?.id,
                            jobOrderId,
                            type: 'KEPT'
                          }
                        }
                      })
                    }} />
                    <CustomButton buttonText='Skip' className="graydark-button my-1" buttonClick={() => {
                      addSearchSetting({
                        variables: {
                          input: {
                            candidateId: candidate?.id,
                            recruiterId: user?.id,
                            jobOrderId,
                            type: 'SKIPPED'
                          }
                        }
                      })
                    }} />
                  </>}
              </div>
            }
          </div>
        </div>
      </div>
      <div className="CandidateTabsWrapper">
        <CandidateDetailDrawer
          data={candidate}
          isOpen={showCandidateDetails}
          toggle={() => setShowCandidateDetails(false)}
          isEditable={false}
        />
      </div>

      <Modal
        isOpen={isPreview}
        toggle={() => setIsPreview(false)}
        centered={true}
        size="xl"
        className="revampDialog border-0"
      >
        <div className="pdf-viewer">
          <Preview
            documentName={resumeData?.name}
            documentUrl={resumeData?.url}
            isDownload={false}
            fileStatus={'Original'}
          />
        </div>
      </Modal>

      <ConfirmPopUp
        confirmText='Are you sure you want to apply this candidate to this job?'
        isOpen={createApplication}
        toggle={applicationToggle}
        confirmAction={async () => {
          const res = await onApplyMutation({
            variables: { candidateId: candidate?.id, jobOrderId },
          });
          if (res?.data?.createSubmissionOnApply?.status === 201) {
            toast.error(`Job applicant already exists with status ${res?.data?.createSubmissionOnApply?.jobApplicant?.JobAppStatus}`);
          }
          else {
            toast.success('Job applicant applied and status changed to Applied with source Recruiter')
            applicationToggle()
            refetch()
          }
        }}
        modalHeading='Apply Candidate'
        btnText='Yes'
        btnColor="primary"
        loading={applying}
        disabled={applying}
        className="revampDialog revampDialogWidth"
      />
    </Col>
  );
};

export default CandidateSearchItem;
