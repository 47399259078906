import { FC } from "react";
import { withRouter } from "react-router-dom";
import PrevImage from "../assets/images/previous.svg";
import { GoBackProps } from "../interfaces";
import { popFromHistory } from "../utils/helper";

const GoBack: FC<GoBackProps> = ({ history, url, goBack, data, search }) => {
  return (
    <div
      className="previous-page cursor-pointer"
      style={{ display: "inline-block" }}
      onClick={() => {
        const backUrl = popFromHistory()
        if (goBack) {
          history.goBack();
          return
        }
        if (url || backUrl) {
          // history.push({ pathname: url, state: data, search });
          history.push(url || backUrl)
        }
      }}
    >
      <img src={PrevImage} alt="back" />
      <span style={{ color: "#eaa827" }}>Back</span>
    </div>
  );
};

export default withRouter(GoBack);
