
import { FC, useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { Col, Row } from "reactstrap";
import APPEND_ICON from "../../../assets/images/add-icon-group.svg"
import { EmploymentHistoryFormProps } from "../../../interfaces";
import { EmploymentHistoryData, IEmploymentHistoryData } from "../../../Pages/agreements/interfaces";
import CustomButton from "../../layout/CustomButton";
import SingleRowWithCheckField from "../basic-information/SingleRowWithCheckField";
import SelectAllCheckRow from "../common/SelectAllCheckRow";
import EmploymentHistorySingleItem from "./EmploymentHistory";
import { useMutation } from "@apollo/client";
import { SET_EMPLOYMENT_INFO } from "../../../Pages/candidate/update-by-resume/gql";
import { IdealWorkType } from "../../../utils/constant";
import { toast } from "react-toastify";

const EmploymentHistoryForm: FC<EmploymentHistoryFormProps> = ({
  candidateId, resumeId, handleBack, handleNext, newData, oldData, disabled }) => {
  const [setEmploymentInfo] = useMutation(SET_EMPLOYMENT_INFO, {
    onCompleted: () => {
      toast.success("employment info updated successfully")
      handleNext()
    },
    onError: () => {
      toast.error("error while updating education info")
    }
  })

  const [selectAllChecks, setSelectAllChecks] = useState<boolean>(false);

  const defaultAssignment = (data: EmploymentHistoryData[]) => data?.map(({ startedAt, endedAt, workType, currentlyWorking, ...rest }): IEmploymentHistoryData => (
    {
      startedAt: new Date(startedAt!)?.toISOString().substring(0, 10),
      endedAt: new Date(endedAt!)?.toISOString().substring(0, 10),
      workType: IdealWorkType?.find(({ value }) => value === workType?.toUpperCase()),
      label: rest?.companyName,
      ...rest
    }))

  const { control, handleSubmit, setValue, errors, getValues } = useForm<{ employmentHistories: IEmploymentHistoryData[] }>({
    defaultValues: {
      employmentHistories: disabled ? defaultAssignment(oldData) : defaultAssignment(newData)
    },
  });

  const { fields, append, remove } = useFieldArray<IEmploymentHistoryData>({
    control,
    name: "employmentHistories",
  });

  const reassignValues = ({ startedAt, endedAt, workType, ...rest }: EmploymentHistoryData, i: number) => {
    const objectKeys = Object.keys(rest)
    objectKeys?.forEach((key) => setValue(`employmentHistories.${i}.${key}`, rest?.[`${key}`] || undefined))
    setValue(`employmentHistories.${i}.startedAt`, new Date(startedAt!)?.toISOString().substring(0, 10))
    setValue(`employmentHistories.${i}.label`, rest.companyName)
    setValue(`employmentHistories.${i}.endedAt`, new Date(endedAt!)?.toISOString().substring(0, 10))
    setValue(`employmentHistories.${i}.workType`, IdealWorkType?.find(({ value }) => value === workType?.toUpperCase()))
  }

  const mapEmpHistories = (empHists: IEmploymentHistoryData[]) => empHists?.map((item, i) => ({
    companyName: item?.companyName,
    jobTitle: item?.jobTitle,
    workType: item?.workType?.value,
    startedAt: item?.startedAt,
    endedAt: item?.endedAt,
    description: item?.description,
    id: oldData[i]?.id,
    mostRecentExperience: item?.currentlyWorking
  }))

  const onSubmit = ({ employmentHistories }: { employmentHistories: IEmploymentHistoryData[] }) => {

    setEmploymentInfo({
      variables: {
        employmentInfo: {
          employmentHistories: mapEmpHistories(employmentHistories), candidateId, resumeId
        }
      }
    })
  };

  return (
    <Col>
      <SelectAllCheckRow newData={!disabled} disabled={disabled || newData?.length > oldData?.length} selectAllChecks={selectAllChecks} setSelectAllChecks={setSelectAllChecks} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <div className="d-flex ">
            <div className="my-3 fs-13 fw-700">Employment History</div>
            {!disabled && <img src={APPEND_ICON} alt="append" hidden className="ml-3" onClick={() => append({})} />}
          </div>
          {fields?.map(({ id, ...rest }, i) => (
            <SingleRowWithCheckField checkBox={!disabled} oldExists={i < oldData?.length} selectAll={selectAllChecks}
              onChecked={() => { reassignValues(oldData[i], i) }}
              onUnChecked={() => {
                if (!disabled) {
                  reassignValues(newData[i], i)
                }
              }}>
              <EmploymentHistorySingleItem control={control} disabled={disabled!} errors={errors} getValues={getValues}
                index={i} openCard={!rest?.companyName || i === 0} header={rest?.companyName!} remove={remove} setValue={setValue}
                size={oldData.length} id={id} />
            </SingleRowWithCheckField>)
          )}
        </div>

        {!disabled && (
          <Col className="mt-5">
            <Row
              className="jobOrderRevamp bg-transparent text-right">
              <Col xl='7' className="my-1">
                <CustomButton
                  buttonText="Previous"
                  buttonColor="secondary"
                  buttonType="button"
                  className="big-width mr-4"
                  buttonClick={handleBack}
                />
              </Col>
              <Col xl='3' className="my-1">
                <CustomButton
                  buttonText="Next"
                  buttonColor="primary"
                  buttonType="submit"
                  className="big-width mr-4"
                />
              </Col>
            </Row>
          </Col>
        )}
      </form>
    </Col>
  );
}

export default EmploymentHistoryForm;
