import { useMutation } from "@apollo/client";
import moment from "moment";
import { useState } from "react";
import { toast } from "react-toastify";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import CustomButton from "../../../components/layout/CustomButton";
import { bucketFolders, MANUAL_RESUME_FILE_SIZE, UPDATE_CANDIDATE_BY_RESUME } from "../../../utils/constant";
import {
  uploadFile, updateFileName,
} from "../../../utils/helper";
import { UPLOAD_RESUME_CANDIDATE, UPLOAD_RESUME_CANDIDATE_WITHOUT_PARSING } from "../gql";
import { Link, useHistory } from "react-router-dom";

const ResumeUpload = ({
  modal,
  toggle,
  candidateId,
  refetch,
  fileType = "",
  driveUpload = false,
}) => {
  console.log("upload")
  const [file, setFile] = useState<any>(null);
  const [updateInfo, setUpdateInfo] = useState<boolean>(false);
  const [fileName, setFileName] = useState<any>("");
  const [errorMessage, setErrorMessage] = useState("");
  const [uploadLogin, setUploadLogin] = useState(false);
  const history = useHistory();

  const [createResume, { loading }] = useMutation(UPLOAD_RESUME_CANDIDATE, {
    onCompleted: (data) => {
      toast.success("Successfully Uploaded!");
      if (updateInfo)
        history.push({ pathname: `${UPDATE_CANDIDATE_BY_RESUME}/${data?.createResume?.candidateId}/${data?.createResume?.resumeId}` })
    },
    onError: ({ message }) => {
      toast.error(message)
    }
  });

  const [createResumeWithoutParsing, { loading: uploadingWithoutParsing }] = useMutation(UPLOAD_RESUME_CANDIDATE_WITHOUT_PARSING, {
    onCompleted: (data) => {
      toast.success("Successfully Uploaded!");
      if (updateInfo)
        history.push({ pathname: `${UPDATE_CANDIDATE_BY_RESUME}/${data?.createResume?.candidateId}/${data?.createResume?.resumeId}` })
    },
    onError: ({ message }) => {
      toast.error(message)
    }
  });


  const uploadAttachment = async (doParsing: boolean = false) => {
    try {
      if (file) {
        setUploadLogin(true);
        let response = await uploadFile(
          new File([file], updateFileName(file.name), { type: file.type }),
          bucketFolders.candidate,
          driveUpload, "resume"
        );
        setUploadLogin(false);
        if (!response.success) {
          toast.error("Unable to upload resume. Please check the file format  ");
        }
        if (response?.duplicateCandidateId === candidateId) {
          toast.warn("resume already exists!");
          return
        }
        if (response.success) {
          const { highlightUrl, fileName, originalUrl, pdfVersionUrl } =
            response;
          const fileType = originalUrl?.split(".").pop();
          if (doParsing) {
            await createResume({
              variables: {
                createResumeDto: {
                  candidateId,
                  originalUrl,
                  duplicateCandidateId: response?.duplicateCandidateId,
                  resumeFileName: fileName,
                  highlightUrl: highlightUrl,
                  fileType,
                  pdfVersionUrl: pdfVersionUrl,
                  gcpExpiry: moment().add(120, "d"),
                  attachmentType: "Manual Resume",
                },
              },
            });
          }
          else {
            await createResumeWithoutParsing({
              variables: {
                createResumeDto: {
                  candidateId,
                  originalUrl,
                  duplicateCandidateId: response?.duplicateCandidateId,
                  resumeFileName: fileName,
                  highlightUrl: highlightUrl,
                  fileType,
                  pdfVersionUrl: pdfVersionUrl,
                  gcpExpiry: moment().add(120, "d"),
                  attachmentType: "Manual Resume",
                },
              },
            });
          }
          setFileName("");
          setFile(null);
          toggle();
          refetch();
        }
      } else {
        setErrorMessage("Please select a file!");
      }
    } catch (error) {
      toast.error("Error while uploading!");
      setUploadLogin(false);
    }
  };

  return (
    <Modal isOpen={modal} className="revampDialog revampDialogWidth">
      <ModalHeader>Upload Resume</ModalHeader>
      <ModalBody>
        <div>
          <div className="user-info upload-btn-wrapper">
            <input
              type="file"
              name="image"
              accept={fileType}
              onChange={async ({ target: { files } }) => {
                setErrorMessage("");
                if (files) {
                  let [file]: any = files;

                  if (file.size > MANUAL_RESUME_FILE_SIZE) {
                    return setErrorMessage(
                      "File size is too large (should be 10MB)."
                    );
                  }
                  if (
                    !(
                      file?.type === "application/pdf" ||
                      file?.type ===
                      "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                      file?.type === "application/msword"
                    )
                  ) {
                    return setErrorMessage(
                      "Please select pdf, doc or docx file"
                    );
                  }
                  if (file) {
                    setFileName(file?.name);
                  }
                  setFile(file);
                }
              }}
            />
            <Button className="bg-success border-0 px-3">Attach File</Button>
          </div>
          <div className="mt-2 text-info text-truncate textTruncateWidthFileName">
            {fileName}
          </div>
          {errorMessage && <div className="text-danger">{errorMessage}</div>}
        </div>
      </ModalBody>
      <ModalFooter className="mt-3">
        <button type="button" onClick={toggle} className="buttonPrimary mr-3">
          Close
        </button>

        <CustomButton
          buttonText="Upload Resume Only"
          buttonColor="primary"
          buttonType="submit"
          className="big-width"
          buttonClick={() => {
            setUpdateInfo(false)
            uploadAttachment(false)
          }}
          loading={!updateInfo && uploadLogin && uploadingWithoutParsing}
          disabled={!updateInfo && uploadLogin}
        />
        <CustomButton
          buttonText="Update Data & Resume"
          buttonColor="primary"
          buttonType="submit"
          className="big-width"
          buttonClick={() => {
            setUpdateInfo(true)
            uploadAttachment(true)
          }}
          loading={updateInfo && (uploadLogin || loading)}
          disabled={updateInfo && (uploadLogin || loading)}
        />
      </ModalFooter>
    </Modal>
  );
};

export default ResumeUpload;
