import { FC, useMemo, useState } from "react"
import { Col, Input, Row } from "reactstrap"
import { ResumeUpdateHistorySingleItemProps } from "../../../interfaces"
import { IdealWorkType } from "../../../utils/constant"
import FormControlInput from "../common/FormControlInput"
import FormControlSelect from "../common/FormControlSelect"
import FormLabel from "../common/FormLabel"
import HistoryCollapse from "../common/HistoryCollapse"
import { Controller } from "react-hook-form"


const EmploymentHistorySingleItem: FC<ResumeUpdateHistorySingleItemProps> = ({
  header, index, disabled, openCard, errors, control, remove, getValues, setValue, size, id }) => {
  const [headerDynamic, setHeaderDynamic] = useState<string>(header || '')
  const removeItem = () => {
    if (index + 1 > size)
      remove(index)
    else
      console.log("sorry", id)
  }

  const formError = useMemo(() => {
    return errors?.employmentHistories?.length ? true : false
  }, [errors?.employmentHistories?.length])

  return (
    <HistoryCollapse text={headerDynamic} open={openCard || formError} remove={removeItem} disabled={true}
      control={control} name={`employmentHistories.${index}.label`}>
      <div className='py-3'>
        <Col>
          <FormLabel text='Company Name' required />
          <FormControlInput required='Company Name is Required' name={`employmentHistories.${index}.companyName`}
            control={control} disabled={disabled!} onChange={(value) => { setHeaderDynamic(value) }} placeholder="Company Name"
            error={errors?.employmentHistories?.length && errors?.employmentHistories[`${index}`]?.companyName?.message} />
        </Col>

        <Col>
          <FormLabel text='Work Type' />
          <FormControlSelect control={control} disabled={disabled} name={`employmentHistories.${index}.workType`}
            options={IdealWorkType} error="" placeholder="Work Type" />
        </Col>

        <Col>
          <FormLabel text='Job Title' />
          <FormControlInput required={false} name={`employmentHistories.${index}.jobTitle`}
            control={control} disabled={disabled!} error={errors?.jobTitle?.message} placeholder="Job Title" />
        </Col>

        <Col>
          <Row className="d-flex align-items-center">
            <Col xs="6">
              <FormLabel text='Start Date' required />
              <FormControlInput type='date' name={`employmentHistories.${index}.startedAt`}
                control={control} disabled={disabled!} required='Start Date is Required' placeholder="Start Date"
                error={errors?.employmentHistories?.length && errors?.employmentHistories[`${index}`]?.startedAt?.message} />
            </Col>
            <Col xs="6">
              <FormLabel text='End Date' />
              <FormControlInput required={false} type='date' name={`employmentHistories.${index}.endedAt`} placeholder="End Date"
                control={control} disabled={disabled! || getValues()?.employmentHistories?.length ? getValues()?.employmentHistories?.[1]?.currentlyWorking : false} error={errors?.endedAt?.message} />
            </Col>
          </Row>
        </Col>

        <Col>
          <Controller
            name={`employmentHistories.${index}.currentlyWorking`}
            control={control}
            render={(field) => (
              <div className="d-flex mt-4">
                <label className={`checkbox-square`}>
                  <Input type="checkbox"
                    disabled={disabled}
                    checked={getValues()?.employmentHistories?.length && getValues()?.employmentHistories?.[index]?.currentlyWorking}
                    onChange={(e) => {
                      setValue(`employmentHistories.${index}.currentlyWorking`, e.target.checked)
                    }}
                  />
                  <span className="checkmark"></span>
                </label>
                <small>Currently Working</small>
              </div>
            )}
          />
        </Col>

        <Col className="mt-3">
          <FormLabel text='Description' />
          <FormControlInput required={false} type='textarea' name={`employmentHistories.${index}.description`}
            control={control} disabled={disabled!} error={errors?.description?.message} placeholder="Description" />
        </Col>
      </div>
    </HistoryCollapse>
  )
}

export default EmploymentHistorySingleItem;
