import { FC } from "react";
import { Col, Row } from "reactstrap";
import { SelectAllCheckRowProps } from "../../../interfaces";

const SelectAllCheckRow: FC<SelectAllCheckRowProps> = ({ disabled, newData, setSelectAllChecks, selectAllChecks }) => {
  return (
    <Col className="pl-0" style={{ paddingRight: 11 }}>
      <Row className='d-flex  align-items-start' >
        <Col xs='10'>
          <div className='compare-form-date-type'>{newData ? 'New Data' : 'Current Data'}</div>
        </Col>
        {!disabled && <Col xs='1'>
          <label className='checkbox-square'>
            <input type="checkbox" onChange={() => setSelectAllChecks(!selectAllChecks)} checked={selectAllChecks} />
            <span className="checkmark"></span>
          </label>
        </Col>}
      </Row>
    </Col>
  );
}

export default SelectAllCheckRow;
