import { FC, useContext } from "react";
import { Controller } from "react-hook-form";
import Select from "react-select";
import { AppContext } from "../../../context";
import { FormControlSelectProps } from "../../../interfaces";
import { customSelectDropDown } from "../../styled/customSelect";


const FormControlSelect: FC<FormControlSelectProps> = ({ name, options, disabled, placeholder, control, requiredMessage, error }) => {
  const { theme } = useContext(AppContext)
  return (
    <>
      <Controller
        name={name}
        as={Select}
        options={options}
        isDisabled={disabled}
        placeholder={placeholder}
        control={control}
        rules={{
          required: {
            value: requiredMessage ? true : false,
            message: requiredMessage!,
          },
        }}
        styles={{ ...customSelectDropDown(theme) }}
      />
      {error && <small className="text-danger">{error}</small>}
    </>
  );
}

export default FormControlSelect;
