import { FC, useEffect, useState } from 'react';
import { Col, Collapse, Row } from 'reactstrap';
import DOWN_ARROW from '../../../assets/images/collapse.svg';
import UP_ARROW from '../../../assets/images/up.svg';
import { HistoryCollapseProps } from '../../../interfaces';
import DELETE_ICON from "../../../assets/images/delete-icon-menu.svg"
import { Controller } from 'react-hook-form';

const HistoryCollapse: FC<HistoryCollapseProps> = ({ children, open, text, disabled, control, remove, name }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  useEffect(() => setIsOpen(open || false), [open])
  return <div className='emp-and-edu-history-collapse py-2 mt-3'>
    <Col>
      <Row onClick={() => setIsOpen(!isOpen)} className='cursor-pointer'>
        <Col xs='11'>
          <div className="d-flex align-items-center">
            {control && <Controller
              control={control!}
              name={name!}
              render={(field) => (
                <label className='fs-13 text-black mt-2' >{field?.value}</label>
              )}
            />}
          </div>
        </Col>
        <Col >
          <img style={{ width: 15 }} src={isOpen ? UP_ARROW : DOWN_ARROW} alt='down-arrow' />
        </Col>
      </Row>
      <Collapse isOpen={isOpen} className='border-0'>
        {children}
      </Collapse>
    </Col>
  </div>
}

export default HistoryCollapse;
