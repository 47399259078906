import { useCallback, useContext, useEffect, useState } from 'react';
// packages block
import {
  Col,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row
} from 'reactstrap';
// components block
import SEARCH_IMG from '../../assets/images/search.svg';
import GoBack from '../../components/GoBack';
import CandidateSearchFilters from './candidate-components/CandidateFilters';
import CandidateSearchList from './candidate-components/CandidateSearchList';
import { ALL_CANDIDATES_ADVANCE_DEV, ALL_CANDIDATES_ADVANCE_SEARCH } from './gql';
// interfaces, graphql, constants block
import { useLazyQuery } from '@apollo/client';
import { State } from 'country-state-city';
import Creatable from 'react-select/creatable';
import Loader from '../../components/layout/Loader';
import { CANDIDATE_SEARCH } from '../../constants';
import { AppContext } from '../../context';
import { SelectorOptionType } from '../../interfaces';
import { countriesIsoCodeDto } from '../../utils/constant';
import CustomButton from '../../components/layout/CustomButton';

const CandidateSearchDev = () => {
  // states
  const [inputValue, setInputValue] = useState<string>('');
  const [advanceSearch, setAdvanceSearch] = useState<{ label: string, value: string, color: string }[]>([]);
  const [city, setCity] = useState<string>('');
  const [excludeSkills, setExcludeSkills] = useState<string[]>([])
  const [selectedState, setState] = useState<SelectorOptionType>({ label: '', value: '' });
  const [includeJobTitle, setIncludeJobTitle] = useState<SelectorOptionType>({ label: '', value: '' });
  const [radius, setRadius] = useState<SelectorOptionType>({ label: '', value: '' });
  const [stateList, setStateList] = useState<{ label: string; value: string }[]>();
  const [selectedCountry, setSelectedCountry] = useState<string>("US");
  const [zipCode, setZipCode] = useState<string>('');
  const [limit, setLimit] = useState<number>(10);
  const [query, setQuery] = useState<string>()
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { setContextSkillsESAggs, skillsESAggs } = useContext(AppContext);
  const [searchCall, setSearchCall] = useState<boolean>(false);
  const [queryResponse, setQueryResponse] = useState();
  const [aggsResult, setAggsResult] = useState();
  const { theme } = useContext(AppContext)
  // api calls
  const [_getCandidatesList, { loading, data }] = useLazyQuery(
    ALL_CANDIDATES_ADVANCE_SEARCH, {
    onCompleted: () => {
      setQueryResponse(data)
    }, onError: () => {
      setQueryResponse(data)
    }
  }
  );

  const [getCandidatesFromQuery, { loading: loadingQueryCandidates, data: dataQueryCandidates }] = useLazyQuery(
    ALL_CANDIDATES_ADVANCE_DEV, {
    onCompleted: () => {
      setQueryResponse(dataQueryCandidates)
    }, onError: () => {
      setQueryResponse(dataQueryCandidates)
    }
  }
  );

  useEffect(() => {
    setQueryResponse(dataQueryCandidates)
  }, [dataQueryCandidates])

  const formatJSON = (text) => {
    try {
      const json = JSON.parse(text);
      return JSON.stringify(json, null, 2); // Format JSON with indentation
    } catch (error) {
      return text; // Return unformatted if there's an error
    }
  };

  const highlightJSON = (text) => {
    return text
      ?.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?)/g, (match) => {
        if (/":$/.test(match)) return `<span style="color: brown;">${match}</span>`; // Key
        return `<span style="color: green;">${match}</span>`; // String value
      })
      ?.replace(/\b(true|false|null)\b/g, '<span style="color: blue;">$1</span>') // Booleans and null
      ?.replace(/\b(-?\d+(\.\d+)?)\b/g, '<span style="color: darkorange;">$1</span>'); // Numbers
  };
  //customized style for search bar
  const customSelectStyleNew = (theme) => ({
    control: (styles, provided) => ({
      ...styles,
      background: theme === "light" ? provided.background : "#282828",
      borderColor: theme === "light" ? "#ffffff" : "#ffffff",
      color: theme === "light" ? provided.background : "#d9d9d9",
      fontSize: 12,
      "&:hover": {
        borderColor: theme === "light" ? "#ffffff" : "#ffffff",
        color: theme === "light" ? provided.background : "#d9d9d9",
      },
      boxShadow: provided.isFocused && `${theme === "light" ? "#ffffff" : "#ffffff"}`
    }),
    menu: (provided) => ({
      ...provided,
      display: 'none'
    }),
    option: (provided) => ({
      ...provided,
      fontSize: 12,
      background: theme === "light" ? provided.background : "#363636",
      "&:hover": {
        background: theme === "light" ? provided.background : "#474444",
        color: theme === "light" ? provided.background : "#d9d9d9",
      },
    }),
    container: (provided, state) => ({
      ...provided,
      fontSize: 12,
      width: '70.87%',
    }),
    placeholder: (provided) => ({
      ...provided,
      opacity: 0.8
    }),
    indicatorSeparator: () => ({
      display: 'none'
    }),
    indicatorsContainer: () => ({
      display: 'none'
    }),
    multiValue: (styles, { data }) => ({
      ...styles,
      backgroundColor: data.color === 'include' ? '#e0ffe0' : '#ffe0e0',
    }),
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: data.color === 'include' ? '#008000' : '#ff0000',
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: data.color === 'include' ? '#008000' : '#ff0000',
      "&:hover": {
        background: data.color === 'include' ? '#cbf8cb' : '#ffbbbb',
        color: data.color === 'include' ? '#008000' : '#ff0000',
      },
    }),
  });

  // component lifecycle methods
  useEffect(() => {
    let statesOfSelectedCountry = State.getStatesOfCountry(
      countriesIsoCodeDto[selectedCountry]
    );

    let statesOfSelectedCountryInLabelValuePair: { label: string; value: string }[] = statesOfSelectedCountry.map(
      (v) => {
        return {
          label: v.name,
          value: v.isoCode,
        };
      }
    );
    setStateList(statesOfSelectedCountryInLabelValuePair);
    setState({ label: '', value: '' });
    setCity('');
    setRadius({ label: '', value: '', })
  }, [selectedCountry]);

  const getCandidatesList = useCallback(() => {
    const searchText = advanceSearch?.filter(({ color }) => color === 'include').map(({ value }) => value)
    const excludeSearch = advanceSearch?.filter(({ color }) => color === 'exclude').map(({ value }) => value)

    console.log("searchText :: ", searchText)
    console.log("excludeSearch :: ", excludeSearch)
    _getCandidatesList({
      variables: {
        limit,
        page: currentPage,
        searchText,
        excludeSearch,
        city: city,
        state: selectedState.label,
        zipCode,
        radius: radius.value,
        excludeSkills,
        skills: skillsESAggs,
        country: selectedCountry
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_getCandidatesList, city, currentPage, limit, radius.value, selectedCountry, selectedState.value, skillsESAggs, zipCode, searchCall]);


  useEffect(() => {
    if (data?.allCandidatesAdvanceSearch?.esQuery) {
      const formattedJSON = formatJSON(data?.allCandidatesAdvanceSearch?.esQuery);
      const highlightedJSON = highlightJSON(formattedJSON);
      document.getElementById("editable")!.innerHTML = highlightedJSON;
      setQuery(highlightedJSON)
    }
    setQueryResponse(data)
    setAggsResult(data?.allCandidatesAdvanceSearch?.aggsResult)
  }, [data])

  useEffect(() => {
    setAggsResult(data?.allCandidatesAdvanceSearch?.aggsResult)
    if (data?.allCandidatesAdvanceSearch?.aggsResult?.length) {
      console.log("data LL ", data?.allCandidatesAdvanceSearch?.aggsResult)
    }
  }, [data?.allCandidatesAdvanceSearch?.aggsResult])

  useEffect(() => {
    setAggsResult(dataQueryCandidates?.allCandidatesAdvanceSearch?.aggsResult)
    if (dataQueryCandidates?.allCandidatesAdvanceSearch?.aggsResult?.length) {
      console.log("dataQueryCandidates LL ", dataQueryCandidates?.allCandidatesAdvanceSearch?.aggsResult)
    }
  }, [dataQueryCandidates?.allCandidatesAdvanceSearch?.aggsResult])

  useEffect(() => {
    getCandidatesList();
  }, [limit, currentPage, getCandidatesList]);


  useEffect(() => {
    setCurrentPage(1)
    setLimit(10)
  }, [city, radius.value, selectedCountry, selectedState.value, skillsESAggs, zipCode, searchCall])

  const handleSkillsFromSearch = (skill: string, del: boolean = false) => {
    if (skillsESAggs?.length) {
      if (del) {
        setContextSkillsESAggs(skillsESAggs?.filter((val) => val !== skill))
        return
      }

      setContextSkillsESAggs([...skillsESAggs, skill])
    }
    else
      setContextSkillsESAggs([skill])
  }
  const clearFilters = () => {
    setState({ label: '', value: '' });
    setCity('');
    setRadius({ label: '', value: '' });
    setAdvanceSearch([])
    setInputValue('')
    setZipCode('');
    setCurrentPage(1);
    setLimit(10);
    setExcludeSkills([]);
    setContextSkillsESAggs([]);
  };

  const handleSkills = (event) => {
    let skills = skillsESAggs?.length ? skillsESAggs : [];
    if (event?.target?.checked) {
      let val = event?.target?.value;
      if (!skills.some((item) => item === val)) {
        skills.push(val);
      }
    } else {
      let val = event?.target?.value;
      skills = skills.filter((item) => item !== val);
    }
    setContextSkillsESAggs([...skills]);
  };

  useEffect(() => {
    return () => {
      setContextSkillsESAggs([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Event Handlers to trigger the search API call when the user presses Enter
  const handleSearchEnterDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      setSearchCall(!searchCall);
      setCurrentPage(1);
    }
  };

  const handleSearchEnterUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      setSearchCall(!searchCall);
    }
  };

  return (
    <div className="admin-tabs team-tabs">
      <GoBack />

      <div className="primaryHeading primaryHeaderSpacing">
        <h5 className="m-0">{CANDIDATE_SEARCH}</h5>
      </div>

      <div className="candidate-search-wrap">
        <div className="bg-white dark-card">
          <div className="candidate-search-sfilter">
            <div className="search-filter d-block searchFilterResponsive p-3">
              <div className="serach-for-candidate mr-2">
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <img src={SEARCH_IMG} alt="search" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Creatable
                    isClearable={false}
                    inputValue={inputValue}
                    onInputChange={(value, actionMeta) => {
                      if (actionMeta.action === 'input-change') {
                        setInputValue(value);
                      }
                    }}
                    value={advanceSearch}
                    multiValueRemove={true}
                    isMulti
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault()
                        setAdvanceSearch([...advanceSearch, { label: inputValue, value: inputValue, color: 'include' }])
                        setInputValue('')
                        setSearchCall(!searchCall)
                      }
                    }}
                    placeholder="Job Title..."
                    onChange={(val) => {
                      setAdvanceSearch(val.map((item) => item))
                      setSearchCall(!searchCall)
                    }}
                    styles={{ ...customSelectStyleNew(theme) }}
                  />

                  <InputGroupAddon addonType="prepend">
                    <button disabled={!inputValue} onClick={() => {
                      setAdvanceSearch([...advanceSearch, { label: inputValue, value: inputValue, color: 'include' }])
                      setInputValue('')
                      setSearchCall(!searchCall)
                    }} className='bg-transparent border-0 px-1'>
                      +                    </button>
                    <button disabled={!inputValue} onClick={() => {
                      setAdvanceSearch([...advanceSearch, { label: inputValue, value: inputValue, color: 'exclude' }])
                      setInputValue('')
                      setSearchCall(!searchCall)
                    }} className='bg-transparent border-0 px-1'>
                      -                    </button>
                  </InputGroupAddon>
                </InputGroup>
              </div>
            </div>
          </div>

          <div className="candidate-search-content">
            <Row>
              <Col md={7} lg={5} xl={4}>
                {!!data?.allCandidatesAdvanceSearch?.esQuery &&
                  <div className="canidate-filters-search p-3">
                    <h5 className="font-weight-bold text-dark">Elastic Search Query</h5>
                    <div
                      id="editable"
                      contentEditable
                      style={{
                        border: "1px solid #ddd",
                        padding: "10px",
                        fontFamily: "monospace",
                        whiteSpace: "pre-wrap",
                        backgroundColor: "#f5f5f5",
                      }}
                      onInput={(event) => { setQuery(event?.currentTarget?.innerText) }}
                      dangerouslySetInnerHTML={{ __html: data?.allCandidatesAdvanceSearch?.esQuery }}
                    />
                    <div className='mt-4 d-flex align-items-center justify-content-end'>
                      <CustomButton
                        buttonText={'Search results'}
                        buttonColor="primary"
                        buttonType="button"
                        buttonClick={() => {
                          getCandidatesFromQuery({
                            variables: {
                              query: query?.replace(/<[^>]+>/g, "")
                            }
                          })
                        }}
                      />
                    </div>
                  </div>
                }
                <CandidateSearchFilters
                  selectedState={selectedState}
                  city={city}
                  zipCode={zipCode}
                  setCity={setCity}
                  setState={setState}
                  setZipCode={setZipCode}
                  radius={radius}
                  setRadius={setRadius}
                  includeJobTitle={includeJobTitle}
                  setIncludeJobTitle={setIncludeJobTitle}
                  getCandidatesList={getCandidatesList}
                  clearFilters={clearFilters}
                  aggregateResult={aggsResult}
                  handleSkills={handleSkills}
                  setCurrentPage={setCurrentPage}
                  stateList={stateList}
                  setStateList={setStateList}
                  selectedCountry={selectedCountry}
                  setSelectedCountry={setSelectedCountry}
                  searchCall={searchCall}
                  handleSkillsFromSearch={handleSkillsFromSearch}
                  setExcludeSkills={setExcludeSkills}
                  excludeSkills={excludeSkills!}
                  setSearchCall={setSearchCall}
                  handleSearchEnterDown={handleSearchEnterDown}
                  handleSearchEnterUp={handleSearchEnterUp}
                />
              </Col>
              <Col md={5} lg={7} xl={8}>
                {!(loading || loadingQueryCandidates) ? (
                  <CandidateSearchList
                    jobOrderId={null}
                    refetch={null}
                    type={null}
                    data={queryResponse!}
                    limit={limit}
                    setLimit={setLimit}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                  />
                ) : (
                  <Loader />
                )}
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CandidateSearchDev;
