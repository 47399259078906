import gql from 'graphql-tag';

export const GET_ALL_PLACEMENT_LIST = gql`
  query AllPlacementList(
    $page: Int!
    $limit: Int!
    $searchText: String
    $candidateId: String
    $jobApplicantId: String
    $jobOrderId: String
  ) {
    allPlacementList(
      payload: { page: $page, limit: $limit }
      filters: {
        searchText: $searchText
        candidateId: $candidateId
        jobApplicantId: $jobApplicantId
        jobOrderId: $jobOrderId
      }
    ) {
      placements {
        id
        candidateName
        candidateEmail
        candidatePhone
        candidateStreet
        candidateCity
        candidateStateCode
        candidatePostalCode
        candidateCountryCode
        jobCity
        jobStreet
        jobStateCode
        jobPostalCode
        jobCountryCode
        placementType
        salaryType
        salaryAmount
        source
        agreement {
          id
        }
        commissionType
        totalCommission
        payableTerm
        replacementTerm
        adjustmentsReason
        adjustmentTotal
        estimatedLessPayrollBurden
        statusNote
        assignmentDuration
        billRate
        payRate
        overtimeBillRate
        estimateEndDate
        finalEndDate
        placementStatus
        createdAt
        jobOrder {
          id
          jobTitle
          advertisedLocations {
            id
            postedAt
          }
          company {
            id
            name
          }
        }
        candidate {
          id
          fullName
          emails {
            id
            isPrimary
            email
          }
        }
        jobApplicant {
          id
        }
      }
      count
    }
  }
`;

export const GET_JOB_ORDER_DETAIL_LIGHT_RECRUITER_ASSIGNED = gql`
  query GetJobOrder($id: String!) {
    getJobOrder(id: $id) {
      id
      recruitingAssigned {
        id
        user {
          id
          fullName
        }
      }
    }
  }
`;

export const GET_JOB_ORDER_DETAIL_LIGHT = gql`
  query GetJobOrder($id: String!) {
    getJobOrder(id: $id) {
      id
      billingContact{
        contact{
          id
          fullName
        }
      }
      mainContact{
        contact{
          id
          fullName
        }
      }
      remoteContact{
        contact{
          id
          fullName
        }
      }
      workType
      company {
        id
        name
        companyContacts {
          id
          fullName
        }
        address {
          id
          isPrimary
          isDeleted
          streetAddress
          city
          state
          zipCode
          country
        }
      }
    }
  }
`;

export const GET_COMPANY_AGREEMENTS_ON_PLACEMENT = gql`
  query GetCompanyAgreementsPlacement($companyId: String!) {
    getCompanyAgreementsPlacement(companyId: $companyId) {
      id
      nickName
      isLegacy
      commissionPercentage
      replacementDays
      agreementType
      payableDays
    }
  }
`;

export const FETCH_CONTACTS_FOR_TIMECARD = gql`
  query FetchAllContacts(
    $page: Int!
    $limit: Int!
    $searchText: String
    $statusSearch: String
    $companyId: String
  ) {
    allContacts(
      payload: { page: $page, limit: $limit }
      filters: {
        searchText: $searchText
        statusSearch: $statusSearch
        companyId: $companyId
      }
    ) {
      contacts {
        id
        fullName
        createdAt
      }
      count
    }
  }
`;

export const CREATE_PLACEMENT = gql`
  mutation CreatePlacement($createPlacementDTO: CreatePlacementDTO!) {
    createPlacement(createPlacementDTO: $createPlacementDTO) {
      id
    }
  }
`;

export const ON_APPROVE_PLACEMENT = gql`
  mutation OnApprovingPlacement($id: String!) {
    onApprovingPlacement(id: $id) {
      message
      status
    }
  }
`;

export const ON_APPROVE_PLACEMENT_ACCOUNTS = gql`
  mutation OnApprovingPlacementAccounts($id: String!) {
    onApprovingPlacementAccounts(id: $id) {
      message
      status
    }
  }
`;

export const ON_REJECT_PLACEMENT_ACCOUNTS = gql`
  mutation OnRejectingPlacementAccounts($id: String!, $reason: String!) {
    onRejectingPlacementAccounts(id: $id, reason: $reason) {
      message
      status
    }
  }
`;

export const ON_REJECT_PLACEMENT = gql`
  mutation OnRejectPlacement($id: String!, $reason: String!) {
    onRejectPlacement(id: $id, reason: $reason) {
      message
      status
    }
  }
`;

export const PLACEMENT_EDIT_NOTES = gql`
  mutation PlacementEditNotes(
    $placementNoteUpdateDTO: PlacementNoteUpdateDTO!
  ) {
    placementEditNotes(placementNoteUpdateDTO: $placementNoteUpdateDTO) {
      message
      status
    }
  }
`;

export const ACCOUNTING_STATUS_CHANGE = gql`
  mutation AccountingStatusChange($id: String!, $status: String!) {
    accountingStatusChange(id: $id, status: $status) {
      message
      status
    }
  }
`;

export const UPDATE_PLACEMENT = gql`
  mutation UpdatePlacement($updatePlacementDTO: UpdatePlacementDTO!) {
    updatePlacement(updatePlacementDTO: $updatePlacementDTO) {
      status
      message
    }
  }
`;

export const ENDED_PLACEMENT = gql`
  mutation endedPlacement($updatePlacementDTO: UpdatePlacementDTO!) {
    endedPlacement(updatePlacementDTO: $updatePlacementDTO) {
      status
      message
      newPlacementID
    }
  }
`;

export const CREATE_PLACEMENT_SPLIT = gql`
  mutation CreatePlacementSplitting(
    $createPlacementSplitDTO: CreatePlacementSplitDTO!
  ) {
    createPlacementSplitting(
      createPlacementSplitDTO: $createPlacementSplitDTO
    ) {
      status
      message
    }
  }
`;
export const APPROVE_EXISTING_RECRUITER_SPLIT = gql`
  mutation ApproveExistingRecruiterSplit($placementId: String!) {
    recruiterSplitApprove(placementId: $placementId) {
      status
      message
    }
  }
`;

export const CREATE_PLACEMENT_RECRUITER_SPLIT = gql`
  mutation CreatePlacementRecruiterSplitting(
    $createPlacementRecruiterSplitDTO: CreatePlacementRecruiterSplitDTO!
  ) {
    createPlacementRecruiterSplitting(
      createPlacementRecruiterSplitDTO: $createPlacementRecruiterSplitDTO
    ) {
      status
      message
    }
  }
`;

export const GET_SINGLE_PLACEMENT_STEP_ONE = gql`
  query GetSinglePlacementStepOne($candidateId: String!, $jobOrderId: String!) {
    getSinglePlacementStepOne(
      candidateId: $candidateId
      jobOrderId: $jobOrderId
    ) {
      id
      overtimeBillRate
      currentPlacementStep
      placementNote
      noteToAccounting
      source
      companyName
      placementType
      placementStatus
      startDate
      startDateString
      netCommission
      feeStructured
      estimateEndDate
      salaryAmount
      salaryType
      commissionType
      jobworkplaceTypes
      agreement {
        id
        nickName
      }
      recruiter {
        id
        fullName
      }
      accountManager {
        id
        fullName
      }
      managerOfAM {
        id
        fullName
      }
      replacementTerm
      payableTerm
      totalCommission
      adjustmentsReason
      adjustmentTotal
      assignmentDuration
      billRate
      payRate
      markupPercentage
      estimatedLessPayrollBurden
      estimatedLessPayrollBurdenPercenatge
      insuranceCost
      peopleTwoFee
      estimatedRecruiterProfit
    }
  }
`;

export const PLACEMENT_SPLITTING = gql`
  query PlacementSplitting($placementId: String!) {
    placementSplitting(placementId: $placementId) {
      id
      splitType
      splitPercentage
      user {
        id
        fullName
      }
    }
  }
`;

export const PLACEMENT_CONTACT = gql`
  query PlacementContact($placementId: String!) {
    placementContact(placementId: $placementId) {
      id
      contactType
      companyContact {
        id
        fullName
      }
    }
  }
`;

export const PLACEMENT_CONTACT_ON_NEW_ASSIGNMENT = gql`
  query PlacementContact($placementId: String!) {
    placementContact(placementId: $placementId) {
      id
      contactType
      companyContact {
        id
        firstName
        lastName
        fullName
        contactPhone {
          id
          phoneType
          number
          isPrimary
        }
        contactEmail {
          id
          email
          isPrimary
        }
        companyLocation {
          id
          streetAddress
          city
          state
          country
          zipCode
        }
      }
    }
  }
`;
export const PLACEMENT_CC_CONTACTS = gql`
  query placementCCContact($placementId: String!) {
    placementCCContact(placementId: $placementId) {
      id
      contactType
      companyContact {
        id
        firstName
        lastName
        fullName
        contactPhone {
          id
          phoneType
          number
          isPrimary
        }
        contactEmail {
          id
          email
          isPrimary
        }
        companyLocation {
          id
          streetAddress
          city
          state
          country
          zipCode
        }
      }
    }
  }
`;

export const GET_SUBMISSION_BY_JOBAPPLICANT = gql`
  query SubmissionCandidatePipelined($jobApplicantId: String!) {
    submissionCandidatePipelined(jobApplicantId: $jobApplicantId) {
      id
      targetSalary
      createdBy {
        id
        fullName
      }
    }
  }
`;

export const GET_AM_MANAGER = gql`
  query placementManagerFinding($userId: String!) {
    placementManagerFinding(userId: $userId) {
      id
      fullName
    }
  }
`;

export const UPDATE_CANDIDATE_OF_PLACEMENT = gql`
  mutation UpdatePlacementCandidate($candidateInput: PlacementCandidateInput!) {
    updatePlacementCandidate(candidateInput: $candidateInput) {
      status
      message
    }
  }
`;
export const UPDATE_JOB_ORDER_OF_PLACEMENT = gql`
  mutation updatePlacementJobOrderInfo(
    $jobOrderInput: PlacementJobOrderInput!
  ) {
    updatePlacementJobOrderInfo(jobOrderInput: $jobOrderInput) {
      status
      message
    }
  }
`;

export const GET_PLACEMENT_PAYMENT_LIST = gql`
  query GetPlacementPaymentHistoryList($getPlacementPaymentHistoryListInput:GetPlacementPaymentHistoryPagination!) {
      getPlacementPaymentHistoryList(getPlacementPaymentHistoryListInput: $getPlacementPaymentHistoryListInput) {
      placementPaymentHistories{
        id
        placementId
        paymentAt
        paymentType
        note
        reminderDays
        emailSent
        updatedAt 
        placement{
          paymentType
          isPaid
        }
      }
    }
  }
`;

export const DELETE_PLACEMENT_PAYMENT_HISTORY_RECORD = gql`
mutation DeletePlacementPaymentHistory($deletePlacementPaymentHistoryInput: DeletePlacementPaymentHistoryDTO!) {
  deletePlacementPaymentHistory(deletePlacementPaymentHistoryInput: $deletePlacementPaymentHistoryInput) {
    message
    status
  }
}
`

export const SEND_NOW_EMAIL_MUTATION = gql`
mutation($invoiceStatusId:String!,) {
  sendEmailNow(invoiceStatusId:$invoiceStatusId) {
    message
    status
  }
}`


export const UPDATE_PAYMENT_HISTORY_RECORD = gql`
mutation UpdatePlacementPaymentHistory($updateHistoryInput:UpdatePlacementPaymentHistoryDTO!) {
  updatePlacementPaymentHistory(updatePlacementPaymentHistoryInput:$updateHistoryInput) {
    message
    status
  }
}
`

export const ADD_PAYMENT_HISTORY_RECORD = gql`
mutation CreatePlacementPaymentHistory($createHistoryInput:CreatePlacementPaymentHistoryDTO!) {
  createPlacementPaymentHistory(createPlacementPaymentHistoryInput:$createHistoryInput) {
    message
    status
  }
}
`