import { useMutation, useQuery } from "@apollo/client";
import { FC, Fragment, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Badge, Button, Spinner } from "reactstrap";
import DELETE_ICON from "../../../../assets/images/delete-icon-menu.svg";
import EDIT_ICON from "../../../../assets/images/edit-icon-menu.svg";
import SEND_NOW_ICON from "../../../../assets/images/send-now.svg";
import ConfirmPopUp from "../../../../components/ConfirmPopUp";
import Loader from "../../../../components/layout/Loader";
import NoDataFound from "../../../../components/layout/NoDataFound";
import TableComponent from "../../../../components/layout/TableComponent";
import { AppContext } from "../../../../context";
import { IInvoiceStatus, InvoiceStatusTableProps } from "../../../../interfaces";
import { formatDateUS } from "../../../../utils/commonFn";
import { INVOICE_SCHEDULED, INVOICE_STATUS_LIST_HEADER, invoiceStatus, ROLES } from "../../../../utils/constant";
import { formatValue, getInvoiceStatusColor } from "../../../../utils/helper";
import InvoiceStatusDrawer from "../../../AccountingDashboard/InvoiceStatusDrawer";
import { DELETE_PLACEMENT_PAYMENT_HISTORY_RECORD, GET_PLACEMENT_PAYMENT_LIST, SEND_NOW_EMAIL_MUTATION } from "../gql";


const SendNowButton = ({ paymentType, userRoles, variables, refetch, sent }) => {
  const [sendNowPaymentHistory, { loading }] = useMutation(SEND_NOW_EMAIL_MUTATION, {
    onCompleted: () => {
      refetch()
      toast.success("Email Sent Successfully")
    },
    onError: () => {
      toast.error("Unable to send Email")
    }
  })
  return (
    <Button className="bg-transparent border-0 p-0"
      hidden={paymentType !== INVOICE_SCHEDULED}
      disabled={!(userRoles?.includes(ROLES.ACCOUNTING_MANAGER) || userRoles?.includes(ROLES.EXECUTIVE)) || sent}>
      {loading ? <Spinner className='mx-0' size='sm' style={{ color: "#eaa827" }} /> :
        <img src={SEND_NOW_ICON} onClick={() => {
          sendNowPaymentHistory({ variables })
        }} alt="send-now" />}
    </Button >
  )
}

const InvoiceStatusTable: FC<InvoiceStatusTableProps> = ({ placementId, invoiceNumber, refetchPlacement }) => {
  //userContext
  const { userRoles } = useContext(AppContext)

  //useStates
  const [drawerStatus, setDrawerStatus] = useState<boolean>(false)
  const [deleteStatus, setDeleteStatus] = useState<boolean>(false)
  const [editStatus, setEditStatus] = useState<boolean>(false)
  const [deletePaymentHistoryId, setDeletePaymentHistoryId] = useState<string>()
  const [editRecord, setEditRecord] = useState<IInvoiceStatus | null>()
  const [paymentHistoryList, setPaymentHistoryList] = useState<IInvoiceStatus[]>()

  //apollo gql
  const { data, loading, refetch } = useQuery(GET_PLACEMENT_PAYMENT_LIST, {
    variables: {
      getPlacementPaymentHistoryListInput: { placementId }
    }
  })

  const [deletePaymentHistory, { loading: deletionLoader }] = useMutation(DELETE_PLACEMENT_PAYMENT_HISTORY_RECORD)

  useEffect(() => {
    setPaymentHistoryList(data?.getPlacementPaymentHistoryList?.placementPaymentHistories)
  }, [data])
  console.log("invoice number table ", invoiceNumber)

  //functions
  const toggle = () => setDrawerStatus(!drawerStatus)

  const deleteEntry = async () => {
    try {
      const response = await deletePaymentHistory({ variables: { deletePlacementPaymentHistoryInput: { id: deletePaymentHistoryId } } })
      console.log("deletedData : ", response)
      if (response?.data?.deletePlacementPaymentHistory?.status === 200) {
        toast.success("Successfully deleted!")
        refetchPlacement && refetchPlacement()
        refetch()
      }
    }
    catch {
      toast.error("Error while deletion!")
    }
    setDeleteStatus(false)
  }

  const getNote = (note: string, date) => {
    if (!note.match(/(\d{2}\/\d{2}\/\d{4})/)) {
      return `${note.replace(/\s\d{2}\/\d{2}\/\d{4}$/, "")} ${formatDateUS(date!)}`
    }
    return note
  }

  const mapInvoiceStatusData = (item: IInvoiceStatus) => {
    const { id, updatedAt, paymentType, paymentAt, note } = item
    return (
      <>
        <td>{formatDateUS(updatedAt!) || "--"}</td>

        <td className='badge-pading'>
          {paymentType ? <Badge
            color={getInvoiceStatusColor(paymentType)}
            className="text-capitalize"
          >
            {formatValue(paymentType)}
          </Badge> : "--"}
        </td>
        <td>{note ? getNote(note, paymentAt) : "--"}</td>
        <td>
          <Button className="bg-transparent border-0 p-0"
            disabled={!(userRoles?.includes(ROLES.ACCOUNTING_MANAGER) || userRoles?.includes(ROLES.EXECUTIVE))}>
            <img className="mr-3" src={EDIT_ICON} onClick={() => {
              setEditRecord(item)
              setEditStatus(true)
              toggle()
            }
            } alt="edit" />
          </Button>

          <Button className="bg-transparent border-0 p-0"
            disabled={!(userRoles?.includes(ROLES.ACCOUNTING_MANAGER) || userRoles?.includes(ROLES.EXECUTIVE))}>
            <img src={DELETE_ICON} onClick={() => {
              setDeleteStatus(true)
              setDeletePaymentHistoryId(id)
            }} alt="delete" />
          </Button >

          <SendNowButton paymentType={paymentType} refetch={refetch} userRoles={userRoles} sent={item?.emailSent}
            variables={{ invoiceStatusId: item.id }} />

        </td>

      </>
    )
  }
  return (
    <>
      <div className="mt-3">
        <Fragment>
          <div className="jobOrderRevamp placementDetail">
            <div className="d-flex align-items-center justify-content-between mb-4">
              <h5 className="mr-2 noMarginHeading">Invoice Status</h5>
              <button
                disabled={!(userRoles?.includes(ROLES.ACCOUNTING_MANAGER) || userRoles?.includes(ROLES.EXECUTIVE))}
                className="buttonGenericStyle filledButton ml-3"
                onClick={() => {
                  setEditStatus(false)
                  setEditRecord(undefined)
                  console.log("editRecord", editRecord)
                  toggle()
                }}
              >
                {"Invoice Status"}
              </button>
            </div>

            <TableComponent tableHeader={INVOICE_STATUS_LIST_HEADER} >
              {!loading && !paymentHistoryList?.length && (
                <tbody>
                  <tr>
                    <td colSpan={9} className="empty-table-td">
                      <NoDataFound text="No Invoice History Found!" />
                    </td>
                  </tr>
                </tbody>
              )}

              {loading ? (
                <tbody>
                  <tr>
                    <td colSpan={11}>
                      <Loader loaderClass="sm-height " />
                    </td>
                  </tr>
                </tbody>
              ) :
                <tbody>
                  {
                    paymentHistoryList?.map((item, index: number) => {
                      return (
                        <tr key={index} >
                          {mapInvoiceStatusData(item)}
                        </tr>
                      );
                    })
                  }
                </tbody>}
            </TableComponent>
          </div>

        </Fragment>
      </div>

      <InvoiceStatusDrawer
        invoiceNumber={invoiceNumber!}
        placementId={placementId}
        isEditButton={editStatus}
        isOpen={drawerStatus}
        toggle={toggle}
        refetchData={() => {
          refetchPlacement && refetchPlacement()
          refetch()
        }}
        editableRecord={editRecord!}
      />

      <ConfirmPopUp
        confirmText="Are you sure you want to delete this invoice status?"
        isOpen={deleteStatus}
        toggle={setDeleteStatus}
        confirmAction={deleteEntry}
        loading={deletionLoader}
        modalHeading="Delete Invoice Status"
        btnText="Delete"
        btnColor="primary"
        className="revampDialog revampDialogWidth"
      />
    </>
  )
}
export default InvoiceStatusTable