import { useLazyQuery, useMutation } from '@apollo/client';
import { useState, useEffect, useContext } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import {
  Alert,
  Badge,
  Button,
  Col,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import GoBack from '../../../../components/GoBack';
import CandidateWidgetPlacement from '../components/CandidateWidgetPlacement';
import JobOrderWidgetDetail from '../components/JobOrderWidgetDetail';
import CLOSE_ICON from '../../../../assets/images/close.svg';
import {
  ACCOUNTING_STATUS_CHANGE,
  ON_APPROVE_PLACEMENT_ACCOUNTS,
  PLACEMENT_SPLITTING,
} from '../gql';
import '../placement.scss';
import Loader from '../../../../components/layout/Loader';
import { useHistory } from 'react-router';
import { useParams } from 'react-router';
import ConfirmPopUp from '../../../../components/ConfirmPopUp';
import AccountsPersonReject from '../../../AccountingDashboard/accounting-components/AccountsPersonReject';
import { GET_PLACEMENT_BY_ID } from '../../../AccountingDashboard/gql';
import { AppContext } from '../../../../context';
import { IComingState } from '../../../agreements/interfaces';
import ViewPlacementForAccounting from '../components/ViewPlacementForAccounting';
import EditPlacement from '../edit/EditPlacement';
import { useForm } from 'react-hook-form';
import { FETCH_CONTACT_HISTORY_DETAIL } from '../../../contacts/gql';
import { CancelAlert } from './CancelAlert';
import { ACCOUNTING_APPROVED, ACCOUNTING_DASHBOARD_ROUTE, ACCOUNTING_INVOICED, DIRECT_HIRE, PAID, VIEW_ACCOUNTING_DASHBOARD_ROUTE, VIEW_PLACEMENT_MANAGEMENT_ROUTE } from '../../../../utils/constant';
import { IAccountManagerPercentage, IRecruiterPercentage } from '../../../../interfaces';
import InvoiceStatusTable from '../components/InvoiceStatusTable';
import InvoiceAttachments from '../../../../components/invoice-attachments';

const ViewAccountingPlacement = () => {
  const location = useLocation<IComingState>();
  const {
    control,
    register,
    errors,
    reset,
    watch,
    setError,
    setValue,
  } = useForm();

  const comingState = location.state;
  const [placementViewReadonlyModal, setPlacementViewReadonlyModal] =
    useState<boolean>(false);
  const [accountingStatusChangeModal, setAccountingStatusChangeModal] =
    useState<boolean>(false);
  const [accountsPersonReject, setAccountsPersonReject] =
    useState<boolean>(false);
  const [actionConfirm, setActionConfirm] = useState(false);
  const [contactWarning, setContactWarning] = useState<boolean>(false);
  const [accountsPersonApproval, setAccountsPersonApproval] =
    useState<boolean>(false);

  const { userRoles } = useContext(AppContext);

  const history = useHistory();
  const { id }: any = useParams();

  const [accountManagerList, setAccountManagerList] = useState<
    Array<IAccountManagerPercentage>
  >([]);
  const [recruiterList, setRecruiterList] = useState<
    Array<IRecruiterPercentage>
  >([]);

  const [_onAccountingStatusChange] = useMutation(ACCOUNTING_STATUS_CHANGE);

  const [_onAccountsPersonApprovingPlacement, { loading }] = useMutation(
    ON_APPROVE_PLACEMENT_ACCOUNTS
  );

  const [_placementSplit, { data: _placementSplitData }] =
    useLazyQuery(PLACEMENT_SPLITTING);

  const [
    _getPlacement,
    { data: _getPlacementData, loading: _getPlacementLoading, refetch },
  ] = useLazyQuery(GET_PLACEMENT_BY_ID);

  const [getContactHistory, { data: contactHistory }] = useLazyQuery(
    FETCH_CONTACT_HISTORY_DETAIL
  );

  useEffect(() => {
    document.title = 'Accounting Dashboard View - RealREPP';

    _getPlacement({
      variables: { id },
    });

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (id) {
      _placementSplit({
        variables: {
          placementId: id,
        },
      });
    }

    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    const accountManagingList: any = [];
    const recruitingList: any = [];
    if (_placementSplitData?.placementSplitting?.length) {
      for (const element of _placementSplitData?.placementSplitting) {
        if (element?.splitType === 'RECRUITING') {
          recruitingList.push({
            percentage: element?.splitPercentage,
            recruiter: {
              label: element?.user?.fullName,
              value: element?.user?.id,
            },
          });
        }
        if (element?.splitType === 'ACCOUNT_MANAGER') {
          accountManagingList.push({
            percentage: element?.splitPercentage,
            accountManager: {
              label: element?.user?.fullName,
              value: element?.user?.id,
            },
          });
        }
      }

      setAccountManagerList(accountManagingList);
      setRecruiterList(recruitingList);
    }

    // eslint-disable-next-line
  }, [_placementSplitData?.placementSplitting]);

  const _onAccountsPersonApprovingPlacementAction = async () => {
    const approveRes = await _onAccountsPersonApprovingPlacement({
      variables: {
        id,
      },
    });
    if (approveRes.data) {
      setActionConfirm(!actionConfirm);
      toast.success('Accounts person approved placement');
      setTimeout(() => {
        history.push(ACCOUNTING_DASHBOARD_ROUTE);
      }, 800);
    }
  };

  const accountingInvoiced = async () => {
    const rejectRes = await _onAccountingStatusChange({
      variables: {
        id,
        status: 'ACCOUNTING_INVOICED',
      },
    });

    if (rejectRes?.data) {
      setAccountingStatusChangeModal(!accountingStatusChangeModal);
      _getPlacement({
        variables: { id },
      });

      toast.success(
        `Placement is set to accounting invoiced by accounting person`
      );
    }
  };

  const changeCompanyContactHandler = (contactId: string) => {
    if (contactId) {
      getContactHistory({ variables: { contactId: contactId } });
    } else {
      setContactWarning(false);
    }
  };

  useEffect(() => {
    if (contactHistory?.getContactHistoryDetail && !contactWarning) {
      setContactWarning(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactHistory?.getContactHistoryDetail]);

  return (
    <div className="admin-tabs team-tabs">
      <>
        <GoBack
          url={ACCOUNTING_DASHBOARD_ROUTE}
          search={comingState?.searchParameters}
        />

        <ToastContainer autoClose={3000} />
        <div className="primaryHeading primaryHeaderSpacing mobileResponsiveFlexAlign justify-content-between">
          <div className="d-flex align-items-center">
            <div>
              <h5 className="m-0">Accounting Dashboard Detail</h5>
              {_getPlacementData?.getPlacementById?.companyFirstPlacement && (
                <Badge color="info" className="ml-2 text-capitalize">
                  First Placement
                </Badge>
              )}
              {_getPlacementData?.getPlacementById?.replacementId && (
                <Button color="link" onClick={() => window.open(`${VIEW_PLACEMENT_MANAGEMENT_ROUTE}/${_getPlacementData?.getPlacementById?.replacementId}`)} target="_blank" className="p-0 text-left text-blue-link">
                  This is a replacement
                </Button>
              )}
            </div>
          </div>
          <div className="mobileResponsiveFlexTablet buttonSpacingTablet">
            {_getPlacementData?.getPlacementById?.placementStatus ===
              'PENDING_ACCOUNTING' &&
              userRoles?.includes('ACCOUNTING_MANAGER') && (
                <>
                  <Button
                    className="border-0 mr-2 buttonGenericStyle"
                    color={'success'}
                    onClick={() => {
                      setAccountsPersonApproval(!accountsPersonApproval);
                    }}
                  >
                    Approve
                  </Button>

                  <Button
                    color={'danger'}
                    className="border-0 mr-2 buttonGenericStyle"
                    onClick={() => {
                      setAccountsPersonReject(!accountsPersonReject);
                    }}
                  >
                    Reject
                  </Button>
                </>
              )}

            {userRoles?.includes('ACCOUNTING_MANAGER') && (
              <div className="mobileResponsiveFlexTablet justify-content-flex-end billingSheetButtons buttonSpacingTablet">
                {_getPlacementData?.getPlacementById?.placementStatus ===
                  'ACCOUNTING_APPROVED' && (
                    <button
                      type="button"
                      color={'success'}
                      onClick={() => {
                        setAccountingStatusChangeModal(
                          !accountingStatusChangeModal
                        );
                      }}
                      className="buttonGenericStyle filledButton mr-3"
                    >
                      Generate Accounting Invoice
                    </button>
                  )}

                {_getPlacementData?.getPlacementById?.placementStatus ===
                  'ACCOUNTING_APPROVED' ||
                  _getPlacementData?.getPlacementById?.placementStatus ===
                  PAID ||
                  _getPlacementData?.getPlacementById?.placementStatus ===
                  'ACCOUNTING_INVOICED' ? (
                  <div>
                    {_getPlacementData?.getPlacementById?.placementType ===
                      'CONTRACT' ? (
                      <div>
                        <button
                          type="button"
                          className="buttonGenericStyle filledButton m-0"
                          onClick={() => {
                            history.push({
                              pathname: `${VIEW_ACCOUNTING_DASHBOARD_ROUTE}/${id}/billingsheet`,
                            });
                          }}
                        >
                          Billing Sheet
                        </button>

                        <button
                          type="button"
                          className="buttonGenericStyle filledButton ml-3 mr-0"
                          onClick={() => {
                            history.push(
                              `${VIEW_ACCOUNTING_DASHBOARD_ROUTE}/${id}/newassignment`
                            );
                          }}
                        >
                          New Assignment
                        </button>
                      </div>
                    ) : (
                      <div>
                        <button
                          type="button"
                          className="buttonGenericStyle filledButton m-0"
                          onClick={() => {
                            history.push({
                              pathname: `${VIEW_ACCOUNTING_DASHBOARD_ROUTE}/${id}/billingsheet`,
                            });
                          }}
                        >
                          Billing Sheet
                        </button>
                      </div>
                    )}
                  </div>
                ) : null}

                <button
                  className="buttonGenericStyle filledButton ml-3"
                  onClick={() => {
                    setPlacementViewReadonlyModal(true);
                  }}
                >
                  View Placement Detail
                </button>
              </div>
            )}
          </div>
        </div>
        {_getPlacementData?.getPlacementById?.placementStatus ===
          'PLACEMENT_CANCELLED' && (
            <CancelAlert
              cancelBy={_getPlacementData?.getPlacementById?.rejectOrApproveBy}
              statusNote={_getPlacementData?.getPlacementById?.statusNote}
            />
          )}

        {contactWarning && (
          <Alert color="danger">
            Company contact{' '}
            <strong>
              {' '}
              {contactHistory?.getContactHistoryDetail?.contact?.fullName}
            </strong>{' '}
            is currently assigned to client{' '}
            <strong>
              {contactHistory?.getContactHistoryDetail?.newCompany?.name}
            </strong>
          </Alert>
        )}

        {_getPlacementLoading ? (
          <Loader />
        ) : (
          <>
            <div className="jobOrderRevamp">
              <Row>
                <Col lg={6} md={6} sm={12} xs={12}>
                  <CandidateWidgetPlacement
                    watch={null}
                    fromWhichPlacement={ACCOUNTING_DASHBOARD_ROUTE}
                    placement={_getPlacementData?.getPlacementById}
                    id={_getPlacementData?.getPlacementById?.candidate?.id}
                    jobId={_getPlacementData?.getPlacementById?.jobOrder?.id}
                    jobApplicantId={
                      _getPlacementData?.getPlacementById?.jobApplicant?.id
                    }
                    candidateOwner={
                      _getPlacementData?.getPlacementById?.recruiter?.fullName
                    }
                    placementAccountsStatus={false}
                    widgetPermissions={true}
                    refetchPlacement={() => { }}
                  />
                </Col>

                <Col lg={6} md={6} sm={12} xs={12}>
                  <JobOrderWidgetDetail
                    watch={null}
                    fromWhichPlacement={ACCOUNTING_DASHBOARD_ROUTE}
                    placement={_getPlacementData?.getPlacementById}
                    candidateId={
                      _getPlacementData?.getPlacementById?.candidate?.id
                    }
                    jobApplicantId={
                      _getPlacementData?.getPlacementById?.jobApplicant?.id
                    }
                    accountManager={
                      _getPlacementData?.getPlacementById?.accountManager
                        ?.fullName
                    }
                    placementAccountsStatus={false}
                    widgetPermissions={true}
                    from="view"
                    id={_getPlacementData?.getPlacementById?.jobOrder?.id}
                    pipeCompanyIdToParent={() => { }}
                    placementStatus={
                      _getPlacementData?.getPlacementById?.placementStatus
                    }
                    contactChangeHandler={changeCompanyContactHandler}
                    refetchPlacement={() => { }}
                  />
                </Col>
              </Row>
            </div>
          </>
        )}

        <div className="mt-3">
          {_getPlacementLoading ? (
            <Loader />
          ) : (
            <ViewPlacementForAccounting
              refetch={() => {
                _getPlacement({
                  variables: { id },
                });
              }}
              accountManagerList={accountManagerList}
              recruiterList={recruiterList}
              viewData={_getPlacementData?.getPlacementById}
            />
          )}
        </div>

        {(_getPlacementData?.getPlacementById?.placementStatus === ACCOUNTING_APPROVED
          || _getPlacementData?.getPlacementById?.placementStatus === ACCOUNTING_INVOICED
          || _getPlacementData?.getPlacementById?.placementStatus === PAID)
          && _getPlacementData?.getPlacementById?.placementType === DIRECT_HIRE
          && <>
            < InvoiceStatusTable invoiceNumber={_getPlacementData?.getPlacementById?.invoiceNumber} refetchPlacement={refetch} placementId={id} />
            <InvoiceAttachments
              companyId={_getPlacementData?.getPlacementById?.jobOrder?.company?.id}
              startDate={_getPlacementData?.getPlacementById?.startDate}
              //invoiceId={_getPlacementData?.getPlacementById?.invoiceNumber}
              placementId={id} placementStatus={_getPlacementData?.getPlacementById?.placementStatus}
              toEmail={_getPlacementData?.getPlacementById?.placementBillingContact?.companyContact?.contactEmail?.find((value) => value?.isPrimary === true)?.email}
              ccEmails={_getPlacementData?.getPlacementById?.placementCcInvoiceTo?.map((value) => value?.companyContact?.contactEmail?.find((value) => value?.isPrimary === true)?.email)}
              accountManagerEmail={_getPlacementData?.getPlacementById?.accountManager?.email}
              emailContent={{
                clientName: _getPlacementData?.getPlacementById?.companyName,
                candidateFullName: _getPlacementData?.getPlacementById?.candidateFullName,
                placementJobTitle: _getPlacementData?.getPlacementById?.jobTitle,
                jobCity: _getPlacementData?.getPlacementById?.jobCity,
                jobStateCode: _getPlacementData?.getPlacementById?.jobStateCode,
                billBoxTag: _getPlacementData?.getPlacementById?.jobOrder?.company?.billBoxTag,
              }}
            />
          </>}
      </>

      <Modal
        isOpen={placementViewReadonlyModal}
        toggle={() =>
          setPlacementViewReadonlyModal(!placementViewReadonlyModal)
        }
        scrollable
        className="for-modal-styling editPlacementDialog"
      >
        <ModalHeader
          toggle={() =>
            setPlacementViewReadonlyModal(!placementViewReadonlyModal)
          }
        >
          <div className="d-flex align-items-center">
            <span>View placement details</span>
            {_getPlacementData?.getPlacementById?.companyFirstPlacement && (
              <Badge color="info font-weight-bold" className="ml-2">
                First Placement
              </Badge>
            )}
          </div>
          <button
            className="iconButton"
            onClick={() => setPlacementViewReadonlyModal(false)}
            type="button"
          >
            <img src={CLOSE_ICON} alt="close" />
          </button>
        </ModalHeader>
        <ModalBody>
          <Form>
            <EditPlacement
              readonly={true}
              setValue={setValue}
              setError={setError}
              watch={watch}
              control={control}
              reset={reset}
              register={register}
              errors={errors}
              viewData={_getPlacementData?.getPlacementById}
            />
          </Form>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            onClick={() => setPlacementViewReadonlyModal(false)}
            className="buttonPrimary mr-3"
          >
            Cancel
          </button>
        </ModalFooter>
      </Modal>

      <ConfirmPopUp
        confirmText={`Are you sure you want to make placement status to Accounting Invoiced?`}
        isOpen={accountingStatusChangeModal}
        toggle={() => {
          setAccountingStatusChangeModal(!accountingStatusChangeModal);
        }}
        confirmAction={accountingInvoiced}
        modalHeading="Confirmation Action"
        btnText={'Verify'}
        btnColor="primary"
        className="revampDialog revampDialogWidth"
      />

      <ConfirmPopUp
        confirmText={
          'Are you sure you want to approve the placement as accounts person?'
        }
        isOpen={accountsPersonApproval}
        toggle={() => {
          setAccountsPersonApproval(!accountsPersonApproval);
        }}
        confirmAction={_onAccountsPersonApprovingPlacementAction}
        modalHeading="Confirmation Action"
        btnText={'Approve'}
        loading={loading}
        disabled={loading}
        btnColor="primary"
        className="revampDialog revampDialogWidth"
      />

      {id &&
        _getPlacementData?.getPlacementById?.candidate?.id &&
        accountsPersonReject && (
          <AccountsPersonReject
            candidateId={_getPlacementData?.getPlacementById?.candidate?.id}
            placementId={id}
            isOpen={accountsPersonReject}
            toggle={() => {
              setAccountsPersonReject(!accountsPersonReject);
            }}
          />
        )}
    </div>
  );
};

export default ViewAccountingPlacement;
