import { gql } from "@apollo/client";

export const SET_EMPLOYMENT_INFO = gql`
mutation SetEmploymentInfoUpdateData($employmentInfo: EmploymentInfoUpdateInput!) {
  setEmploymentInfoUpdateData(employmentInfo: $employmentInfo) {
    message
    status
  }
}
`
export const SET_EDUCATION_INFO = gql`
mutation SetEducationInfoUpdateData($educationInfo: EducationInfoUpdateInput!) {
  setEducationInfoUpdateData(educationInfo: $educationInfo) {
    message
    status
  }
}
`
export const SET_BASIC_INFO = gql`
mutation SetBasicInfoUpdateData($basicInfo: BasicInfoUpdateInput!) {
  setBasicInfoUpdateData(basicInfo: $basicInfo) {
    message
    status
  }
}
`
export const GET_BASIC_INFO = gql`
query($candidateId:String!,$resumeId:String!){
  getBasicInfoUpdateData(candidateId:$candidateId,resumeId:$resumeId){
    olderData{
      firstName
      middleName
      lastName
      email
      phone
      phoneType
      streetAddress
      city
      stateCode
      postalCode
      countryCode
      twitterUrl
      fbUrl
      instaUrl
      linkedInUrl
    },
     newData{
      firstName
      middleName
      lastName
      email
      phone
      phoneType
      streetAddress
      city
      stateCode
      postalCode
      countryCode
      twitterUrl
      fbUrl
      instaUrl
      linkedInUrl
    }
  }
}
`
export const GET_EMPLOYMENT_INFO = gql`
query($candidateId:String!,$resumeId:String!){
  getEmploymentInfoUpdateData(candidateId:$candidateId,resumeId:$resumeId){
    olderData{
      id
      companyName
      workType
      jobTitle
      startedAt
      endedAt
      description
      currentlyWorking
    }
    newData{
      id
      companyName
      workType
      jobTitle
      startedAt
      endedAt
      description
      currentlyWorking
    }
  }
}
`
export const GET_EDUCATION_INFO = gql`
query($candidateId: String!, $resumeId: String!) {
  getEducationInfoUpdateData(candidateId: $candidateId, resumeId: $resumeId) {
    olderData {
      id
      schoolName
      degreeEarned
      fieldOfStudy
      startMonth
      endMonth
      startYear
      endYear
    }
    newData {
      id
      schoolName
      degreeEarned
      fieldOfStudy
      startMonth
      endMonth
      startYear
      endYear
    }
  }
}
`