import React, { FC } from 'react';
import { FormLabelProps } from '../../../interfaces';

const FormLabel: FC<FormLabelProps> = ({ text, required }) => {
  return (
    <label className='form-label'>
      {text}  {required && <span className="text-danger">*</span>}
    </label>
  );
}

export default FormLabel;
